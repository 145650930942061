<app-alert-success
  *ngIf="isDataSaved || successMessage"
  [message]="successMessage"
  (closeMessageEvent)="closeSuccessMessage()"
></app-alert-success>
<app-alert-error></app-alert-error>
<div style="padding-left: 20px">
  <div class="em-l em-l--two-column">
    <div class="em-l__main">
      <div class="em-c-field em-c-field--inline em-c-field--small">
        <img
          *ngIf="!isUploadeUtility"
          src="assets/images/manufacturers/{{
            candidateConnection.manufacturerLogo
          }}"
          alt="alt text"
          class="em-c-media-block__img"
        />
        <img
          *ngIf="isUploadeUtility && isDataSaved"
          src="assets/images/manufacturers/{{
            candidateConnection.manufacturerLogo
          }}"
          alt="alt text"
          class="em-c-media-block__img"
        />
        <h2 class="em-c-media-block__headline title">
          <span *ngIf="!isUploadeUtility">
            {{ candidateConnection.od }} {{ candidateConnection.odUOM }} OD,
            {{ candidateConnection.weight }}
            {{ candidateConnection.weightUOM }},
            {{ candidateConnection.gradesReportString }},
            {{ candidateConnection.manufacturer }},
            {{ candidateConnection.connectionName }}
          </span>
          <span
            *ngIf="isUploadeUtility && !isDataSaved"
            class="tcdb-new-connection-title-before-save"
          >
            [OD + UoM] OD, [Weight + UoM], [Grade], [Manufacturer Name]
            [Connection Name]
          </span>
          <span *ngIf="isUploadeUtility && isDataSaved">
            {{ candidateConnection.od }} {{ candidateConnection.odUOM }} OD,
            {{ candidateConnection.weight }}
            {{ candidateConnection.weightUOM }},
            {{ candidateConnection.gradesReportString }},
            {{ candidateConnection.manufacturer }},
            {{ candidateConnection.connectionName }}
          </span>
        </h2>
      </div>
    </div>

    <!-- Share to Drill Plan -->
    <div
      *ngIf="
        _loadCaseService.isFromDrillPlan &&  this.candidateConnection.connectionId &&
        !isConnectionEdit &&
        this.tabStorageService.adminUser
      "
      class="em-l-grid__item align-end tcdb-cursor-pointer hover-share-drill"
      style="
        display: flex;
        flex-direction: row;
        gap: 15px;
        /* padding-top: 10px; */
        border-width: 1px;
        border-style: solid;
        border-color: rgba(215, 215, 215, 1);
        border-radius: 3px;
        height: 37px;
        width: 140px;
        margin-top: 7px;
        padding-top: 4px;
        padding-bottom: -10px;
        height: 29px;
      "
      (click)="canShareToDrillPlan($event)"
      [ngClass]="{'disable-drill-plan': !_searchCriteriaService.isShareToDrillPlanEnabled,'hover-share-drill': _searchCriteriaService.isShareToDrillPlanEnabled, 'disable-hover': !_searchCriteriaService.isShareToDrillPlanEnabled}"
      [matTooltip]="getShareToDrillPlanToolTip()"
    >
      <div style="margin-top: 2px">
        <a
          _ngcontent-ng-cli-universal-c55=""
          href="javascript:void(0);"
        >
          <img
            _ngcontent-ng-cli-universal-c55=""
            id="u225_img"
            src="../../../assets/u200.svg"
            class="img"
            style="width: 14px"
        /></a>
      </div>
      <div style="font-weight: 600; font-size: 12px; padding-top: 3px">
        Share to DrillPlan
      </div>
    </div>
    <!-- Edit -->
    <div
      class="em-l-grid__item align-end"
      *ngIf="
        this.candidateConnection.connectionId &&
        !isConnectionEdit &&
        this.tabStorageService.adminUser
      "
    >
      <a href="javascript:void(0);" (click)="editConnection()">
        <svg class="em-c-icon em-c-icon--medium">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#edit"
          ></use>
        </svg>
      </a>
    </div>
    <!-- Delete -->
    <div
      class="em-l-grid__item align-end"
      *ngIf="
        this.candidateConnection.connectionId &&
        !isConnectionEdit &&
        this.tabStorageService.adminUser
      "
    >
      <a href="javascript:void(0);" (click)="deleteConnection()">
        <svg class="em-c-icon em-c-icon--medium">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#trashcan"
          ></use>
        </svg>
      </a>
    </div>

    <!-- XLSX -->
    <div
      class="em-l-grid__item align-end"
      *ngIf="this.candidateConnection.connectionId && !isConnectionEdit"
    >
      <a href="javascript:void(0);">
        <svg
          class="em-c-icon em-c-icon--medium"
          (click)="downloadCandidateExcelFile()"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#file-xlsx"
          ></use>
        </svg>
      </a>
    </div>

    <!-- PDF -->
    <div
      class="em-l-grid__item align-end"
      *ngIf="this.candidateConnection.connectionId && !isConnectionEdit"
    >
      <a href="javascript:void(0);">
        <svg class="em-c-icon em-c-icon--medium" (click)="openPDF()">
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#file-pdf-2"
          ></use>
        </svg>
      </a>
    </div>
    <div class="em-l__secondary">
      <button class="em-c-btn back-button" (click)="onBackClick()">
        <div class="em-c-btn__inner">
          <svg
            class="em-c-icon em-c-icon--medium em-c-btn__icon"
            data-em-icon-path="../../../../../assets/vendor/unity/images/em-icons.svg"
          >
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="../../../../../assets/vendor/unity/images/em-icons.svg#chevron-left-filled"
            ></use>
          </svg>
          <span class="em-c-btn__text">Back</span>
        </div>
      </button>
    </div>
  </div>
  <div class="em-c-tabs em-c-tabs--underline em-js-tabs">
    <ul class="em-c-tabs__list">
      <li
        class="em-c-tabs__item"
        *ngFor="let tab of tabList"
        [ngClass]="{ 'tcdb-tab-disabled': tab.isHeaderDisabled }"
      >
        <a
          href="javascript:void(0);"
          class="em-c-tabs__link em-js-tab"
          [ngClass]="{
            'em-is-active': tab.id === tabActive,
            'tcdb-tab-disabled-color': tab.isHeaderDisabled
          }"
          (click)="onTabClick(tab.id)"
          >{{ tab.title }}</a
        >
      </li>
    </ul>
    <!-- end em-c-tabs__list -->
    <div class="em-c-tabs__body em-c-tabs__body--no-border">
      <div
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'detailstab' ? 'em-is-active' : ''"
      >
        <app-details-tab-item
          *ngIf="this.candidateConnection.connectionId && !isConnectionEdit"
          [detailsList]="candidateConnection"
        ></app-details-tab-item>
        <!-- <app-details-section *ngIf="isConnectionEdit"></app-details-section> -->

        <app-details-section
          *ngIf="!this.candidateConnection.connectionId"
        ></app-details-section>
        <app-details-section
          [isEdit]="isConnectionEdit"
          *ngIf="this.candidateConnection.connectionId && isConnectionEdit"
        ></app-details-section>

        <div class="chart-container chart-placement-forPDF">
          <canvas id="LiquidChartPDF">{{ liquidChartPDF }}</canvas>
          <canvas id="GasChartPDF">{{ gasChartPDF }}</canvas>
          <canvas id="StructuralChartPDF">{{ structuralChartPDF }}</canvas>
        </div>
      </div>
      <div
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'testinfotab' ? 'em-is-active' : ''"
      >
        <!-- From search -->
        <app-tests-information-tab
          *ngIf="this.candidateConnection.connectionId && !isConnectionEdit"
          [candidateConnectionResult]="candidateConnection"
        ></app-tests-information-tab>

        <app-test-information-section
          *ngIf="
            (this.candidateConnection.connectionId && isConnectionEdit) ||
            (!this.candidateConnection.connectionId && !isConnectionEdit)
          "
        ></app-test-information-section>
      </div>

      <div
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'connectionPerformance' ? 'em-is-active' : ''"
      >
        <app-connection-performance-section
          [connectionPerformances]="candidateConnection?.connectionPerformances"
          [isTableColumnsEditable]="isTableColumnsEditable"
          [isConnectionEdit]="isConnectionEdit"
          (NewEnvelopeTypeAdded)="handleNewEnvelopeTypeAdded($event)"
          [connectionId]="candidateConnection.connectionId"
        ></app-connection-performance-section>
      </div>
      <div
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'liquidsealabilitytab' ? 'em-is-active' : ''"
      >
        <h3>Liquid Sealability</h3>

        <div class="em-l em-l--two-column-wide">
          <div class="chart-container chart-placement">
            <canvas id="LiquidChart">{{ liquidChart }}</canvas>
          </div>

          <div class="em-l__main envelope-header">
            <h4 class="legend-title">Envelopes</h4>
            <div style="padding-top: 20px">
              <div *ngFor="let envelope of liquidCheckboxes">
                <label>
                  <input
                    data-testid="viewdetails-legend-checkbox"
                    type="checkbox"
                    value="{{ envelope.key }}"
                    class="checkbox"
                    [id]="'checkbox-' + envelope.key"
                    (change)="onLiquidCheckboxChange($event, envelope.title)"
                    [checked]="envelope.checked"
                  />
                  {{ envelope.title }}
                </label>
              </div>
            </div>

            <div>
              <h4 class="legend-title">Loads</h4>
              <app-expansion-panel-checkboxes
                *ngFor="
                  let sectionExpansionPanel of liquidSectionExpansionPanelArray
                "
                [expansionPanel]="sectionExpansionPanel"
                (checkBoxEvent)="updateExpansionPanel($event, 'liquid')"
              ></app-expansion-panel-checkboxes>
            </div>
          </div>
        </div>
        <div *ngIf="candidateConnection.isHighCollapseCriterion">
          Connection with collapse rating higher than API collapse rating
        </div>
        <div>
          Connection with factor
          {{ candidateConnection.isWallThickness90GText }}% remaining body wall
          thickness
        </div>
      </div>

      <div
        *ngIf="isUploadeUtility"
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'liquidsealabilitytab' ? 'em-is-active' : ''"
      >
        <h3>Liquid Sealability</h3>

        <div class="em-l em-l--two-column-wide">
          <div class="chart-container chart-placement">
            <canvas id="LiquidChart">{{ liquidChart }}</canvas>
          </div>

          <div class="em-l__main envelope-header">
            <h4 class="legend-title">Envelopes</h4>
            <div style="padding-top: 20px">
              <div *ngFor="let envelope of liquidCheckboxes">
                <label>
                  <input
                    data-testid="viewdetails-legend-checkbox"
                    type="checkbox"
                    value="{{ envelope.key }}"
                    class="checkbox"
                    [id]="'checkbox-' + envelope.key"
                    (change)="onLiquidCheckboxChange($event, envelope.title)"
                    [checked]="envelope.checked"
                  />
                  {{ envelope.title }}
                </label>
              </div>
            </div>

            <div>
              <h4 class="legend-title">Loads</h4>
              <app-expansion-panel-checkboxes
                *ngFor="
                  let sectionExpansionPanel of liquidSectionExpansionPanelArray
                "
                [expansionPanel]="sectionExpansionPanel"
                (checkBoxEvent)="updateExpansionPanel($event, 'liquid')"
              ></app-expansion-panel-checkboxes>
            </div>
          </div>
        </div>
        <div *ngIf="candidateConnection.isHighCollapseCriterion">
          Connection with collapse rating higher than API collapse rating
        </div>
        <div>
          Connection with factor
          {{ candidateConnection.isWallThickness90GText }}% remaining body wall
          thickness
        </div>
      </div>

      <div
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'gasandliquidtab' ? 'em-is-active' : ''"
      >
        <h3>Gas & Liquid Sealability</h3>

        <div class="em-l em-l--two-column-wide">
          <div class="chart-container chart-placement">
            <canvas id="GasChart">{{ gasChart }}</canvas>
          </div>

          <div class="em-l__main envelope-header">
            <h4 class="legend-title">Envelopes</h4>
            <div style="padding-top: 20px">
              <div *ngFor="let gasEnvelope of gasCheckboxes">
                <label>
                  <input
                    data-testid="viewdetails-legend-checkbox"
                    type="checkbox"
                    value="{{ gasEnvelope.key }}"
                    class="checkbox"
                    [id]="'checkbox-' + gasEnvelope.key"
                    (change)="onGasCheckboxChange($event, gasEnvelope.title)"
                    [checked]="gasEnvelope.checked"
                  />
                  {{ gasEnvelope.title }}
                </label>
              </div>
            </div>

            <div>
              <h4 class="legend-title">Loads</h4>
              <app-expansion-panel-checkboxes
                *ngFor="
                  let sectionExpansionPanel of gasSectionExpansionPanelArray
                "
                [expansionPanel]="sectionExpansionPanel"
                (checkBoxEvent)="updateExpansionPanel($event, 'gas')"
              ></app-expansion-panel-checkboxes>
            </div>
          </div>
        </div>
        <div *ngIf="candidateConnection.isHighCollapseCriterion">
          Connection with collapse rating higher than API collapse rating
        </div>
        <div>
          Connection with factor
          {{ candidateConnection.isWallThickness90GText }}% remaining body wall
          thickness
        </div>
      </div>
      <div
        class="em-c-tabs__panel em-js-tabs-panel"
        [ngClass]="tabActive === 'structuraltab' ? 'em-is-active' : ''"
      >
        <h3>Structural</h3>

        <div class="em-l em-l--two-column-wide">
          <div class="chart-container chart-placement">
            <canvas id="StructuralChart">{{ structuralChart }}</canvas>
          </div>

          <div class="em-l__main envelope-header">
            <h4 class="legend-title">Envelopes</h4>
            <div style="padding-top: 20px">
              <div *ngFor="let envelope of structuralCheckboxes">
                <label>
                  <input
                    data-testid="viewdetails-legend-checkbox"
                    type="checkbox"
                    value="{{ envelope.key }}"
                    class="checkbox"
                    [id]="'checkbox-' + envelope.key"
                    (change)="
                      onStructuralCheckboxChange($event, envelope.title)
                    "
                    [checked]="envelope.checked"
                  />
                  {{ envelope.title }}
                </label>
              </div>
            </div>

            <div>
              <h4 class="legend-title">Loads</h4>
              <app-expansion-panel-checkboxes
                *ngFor="
                  let sectionExpansionPanel of structuralSectionExpansionPanelArray
                "
                [expansionPanel]="sectionExpansionPanel"
                (checkBoxEvent)="updateExpansionPanel($event, 'structural')"
              ></app-expansion-panel-checkboxes>
            </div>
          </div>
        </div>
        <div *ngIf="candidateConnection.isHighCollapseCriterion">
          Connection with collapse rating higher than API collapse rating
        </div>
        <div>
          Connection with factor
          {{ candidateConnection.isWallThickness90GText }}% remaining body wall
          thickness
        </div>
      </div>
    </div>
  </div>
  <app-save-discard
    *ngIf="
      (isUploadeUtility || isConnectionEdit) && this.tabStorageService.adminUser
    "
    (Saved)="saveConnection()"
    (Discarded)="discarded()"
    [DisableDiscard]="!isConnectionModified"
    [SaveDiscard]="
      (isConnectionEdit && !isConnectionModified) ||
      uploadeUtilityService.isManualEntry
    "
  ></app-save-discard>
</div>
<app-confirmation-modal
  [showConfirmationModal]="showConfirmationModal"
  (emitResult)="deleteConnectionOnConfirmation($event)"
  [confirmationText]="deleteConnectionConstants.deleteMessage"
  [confirmationTitle]="deleteConnectionConstants.deleteTitle"
  [confirmButtonText]="deleteConnectionConstants.DeleteYes"
  [cancelButtonText]="deleteConnectionConstants.DeleteNo"
></app-confirmation-modal>

<app-confirmation-modal
  [showConfirmationModal]="showShare2DrillPlanConfirmation"
  (emitResult)="shareReportToDrillPlan($event)"
  [confirmationText]="share2DrillPlanConstants.Message"
  [confirmationTitle]="share2DrillPlanConstants.Title"
  [confirmButtonText]="share2DrillPlanConstants.Yes"
  [cancelButtonText]="share2DrillPlanConstants.No"
></app-confirmation-modal>

<app-candidate-view-details-export-pdf
  [liquidChartToPDF]="liquidChartToPDF"
  [gasChartToPDF]="gasChartToPDF"
  [structuralChartToPDF]="structuralChartToPDF"
  [candidateConnection]="candidateConnection"
  [section_liquid]="section_liquid"
  [section_structure]="section_structure"
  [section_gas]="section_gas"
  [caseDescriptionPDF]="caseDescription"
  [sectionArrayList]="sectionArrayList"
></app-candidate-view-details-export-pdf>

<!-- end em-c-tabs -->
