import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertErrorService } from '../../../../service/tcdb/alert-error.service';
import { UploadUtilityService } from '../upload-utility/upload-utility.service';
import { LoaderService } from 'app/service/tcdb/loader.service';

@Component({
  selector: 'app-add-new-connection',
  templateUrl: './add-new-connection.component.html',
  styleUrls: ['./add-new-connection.component.css']
})
export class AddNewConnectionComponent implements OnInit{
  connectionMethod: string;
  uploadModalAddConnection: boolean = false;
  selectedFile: File | null = null;
  seletedFileData: any;
  showError: boolean = false;
  fileName: string;
  domainName: string;
  public wait = ms => new Promise(resolve => setTimeout(resolve, ms));
  constructor(private router: Router, private uploadUtilityService: UploadUtilityService, private loaderService: LoaderService, private _alertErrorService: AlertErrorService) {
    this._alertErrorService.errorMessage.next('');
  }

  ngOnInit(): void {
    this.uploadUtilityService.isValidationDone.subscribe(x => {
      if (!x) {
        this._alertErrorService.errorMessage.next(this.uploadUtilityService.errorMessage);
      }
    })
  }

  

  close() {
    localStorage.setItem('AddNewConnection', '');
    this.router.navigate(['/search']);
  }

  onContinue() {
    if (this.connectionMethod !== undefined) {
      if (this.connectionMethod == "upload") {
        this.uploadModalAddConnection = true;
      } else {
        this.router.navigate(['/upload-utility', true]);
        this.uploadUtilityService.isManualEntry = true;
        this.uploadUtilityService.isCSEUpload = false;
      }
    }
  }
  closeUploadModalAddConnection() {
    this.uploadModalAddConnection = false;
    this.showError = false;
    this.selectedFile = null;
    this.fileName = "";
  }
  onFileSelected(event: any) {
    this._alertErrorService.errorMessage.next('');
    this.selectedFile = event.target.files[0];
    const file: File = event.target.files[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.showError = false;
      this.fileName = file.name;
      this.seletedFileData = event;
      this.uploadUtilityService.isCSEUpload = true;
    } else {
      this.showError = true;
      this.selectedFile = null;
      this.fileName = "";
    }
  }
  uploadFile() {
    if (this.selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.showError = false;
      this.uploadUtilityService.errorMessage = undefined;
      this.fileName = this.selectedFile.name;
      this.uploadUtilityService.onFileChange(this.seletedFileData);
      this.uploadModalAddConnection = false;
      this.selectedFile = null;
      this.fileName = "";
    } else {
      this.showError = true;
      this.selectedFile = null;
      this.fileName = "";
    }
    
  }

  downloadTemplate() {
    this.loaderService.isLoading.next(true);
    this.domainName = window.origin;
    const url = this.domainName + '/' + 'assets/template/TCDB_CES_Template.xlsm';
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'TCDB_CES_Template.xlsm';
        link.click();
        window.URL.revokeObjectURL(link.href);
        this.loaderService.isLoading.next(false);
      })
      .catch(error => { this.loaderService.isLoading.next(false); console.error('Download error:', error) });
  }
}
