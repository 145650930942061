import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { section } from '../../../../common/models/section.model';
import { welldesc } from '../../../../common/models/welldesc.model';
import { editLoad, load, pressureAndTension, sealabilityEnvelope } from '../../../../common/models/load.model';
import { LoadCaseService } from '../../../../service/tcdb/loadcaseservice';
import { ITableRow } from '../../../../common/models/components/iTableRow';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { Constants } from '../../../../common/constants/constant';
import { deepCopy, isValidTextWithSomeSpecialCharacterRestrictions, sleep } from '../../../../common/utilities/utilities';
import { IExportToExcel, IExportToExcelRow } from '../../../../common/models/components/iExportToExcel';
import { ExportToExcelComponent } from '../../components/export-to-excel/export-to-excel.component';
import { ImportExcel } from '../../../../common/models/components/iImportExcel';
import { UnitOfMeasureService } from '../../../../service/tcdb/unitofmeasure.service';
import { MainHeaderService } from '../../../../service/tcdb/mainheader.service';
import * as XLSX from 'xlsx';
import { AlertErrorService } from '../../../../service/tcdb/alert-error.service';
import { AlertErrorComponent } from '../../components/alert-error/alert-error.component';
import { SectionComponent } from '../../components/section-control/section-control.component';
import { DisplayTableComponent } from '../../components/display-table/display-table.component';
import { MeasureService } from '../../../../service/tcdb/measure.service';
import { IExpansionPanelItem, IExpansionPanelModel } from '../../../../common/models/components/expansionpanelmodel.interface';
import { TabStorageService } from '../../../../service/tcdb/tabstorage.service';
import { MsalService } from '@azure/msal-angular';
import { Share2DrillPlanDisclaimerFoLoadsCase } from '../../components/upload-utility/upload-utility-helper';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.css']
})
export class LoadComponent implements OnInit {
  _userFullName: string;
  _userPrincipalName: string;
  uploadedFile: File;
  showLoad: boolean = false;
  load: load = new load();
  savedEditLoad = new load();
  sectionArray= [];
  sectionIndexToAddOrEditLoad = 0;
  sealabilityError: boolean = false;
  sealabilityErrorMessage: string = Constants.SealabilityEnvelopeError;
  loadNameError: boolean = false;
  loadNameErrorMessage: string = '';
  loadCommentsError: boolean = false;
  loadCommentsErrorMessage: string = '';
  pressureAndTensionHeader: ITableHeader[] = [];
  pressureAndTensionLoadList: ITableRow[] = [];
  useAddRow = true;
  isToEdit = false;
  editLoadSectionIndex = 0;
  loadTitleConfirmationText;
  loadNameForm = '';
  loadCommentsForm = '';
  wellNameForm = '';
  wellNameError: boolean = false;
  wellNameErrorMessage = '';
  wellName: string = '';
  caseDescription: string = '';
  caseDescriptionError: boolean = false;
  caseDescriptionErrorMessage = '';
  successMessage = '';
  loadConfirmationModalOpen = false;
  loadConfirmationText = Constants.LoadDeleteConfirmation;
  fileName = 'Loads.xlsx';
  showImportConfirmationModal = false;
  importLoadsConfirmationModalText = Constants.ImportLoadsConfirmation;
  @ViewChild(ExportToExcelComponent) exportToExcelComponent: ExportToExcelComponent;
  @ViewChild(AlertErrorComponent) alertErrorComponent: AlertErrorComponent;
  @ViewChild(SectionComponent) sectionComponent: SectionComponent;
  @ViewChild(DisplayTableComponent) displayTableComponent: DisplayTableComponent;
  loadEmptyTitle: string = Constants.LoadEmptyMessageTitle;
  loadEmptyMessage: string = Constants.LoadEmptyMessage;
  paddingVal = "load-heading";
  userRoles: string[];
  adminUser: boolean = false;
  tabId: string;
  isToShowButtonsInLandingPage: boolean = false;
  excelExport: IExportToExcel[] = [];
  isBlankExcelTemplate: boolean = false;
  sectionModalOpenForWell = false;
  wellModel = new welldesc();
  wellTitleConfirmationText: string;
  wellConfirmationText: string;
  wellConfirmationModalOpen: boolean;
  actionList = ['Edit', 'Delete'];
  showPressureTensionModifiedModelForDP: boolean;
  share2DrillPlanDisclaimerFoLoadsCase = Share2DrillPlanDisclaimerFoLoadsCase;



  expansionFilter: IExpansionPanelModel = {
    title: '', items: [], usecheckbox: false, sort: 0, enableSecondLevel: false, icon: 'more-vertical'
  };
  constructor(private _loadCaseService: LoadCaseService, private _alertErrorService: AlertErrorService, private _unitOfMeasure: UnitOfMeasureService, private _mainHeaderService: MainHeaderService, private _measureService: MeasureService, private _tabStorageService: TabStorageService, private _msalService: MsalService) {
    localStorage.setItem('MenuSelected', 'load');
    this.successMessage = '';
    this.tabId = 'LoadsToSave_' + this._tabStorageService.getTabId();
    this._unitOfMeasure.savedUnitOfMeasure.subscribe((v) => {
      this.reloadSectionWithUOM();
      this.reloadHeaderUOM();
    });
    let items = [];
    this.actionList.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      items.push(obj);
    });
    this.expansionFilter.items = items;
  }

  ngOnInit() {
    this.userRoles = this._msalService.instance.getAllAccounts()[0].idTokenClaims.roles;
    this.adminUser = this.userRoles.includes('TCDBAdministrator');
    this._tabStorageService.adminUser = this.adminUser;
    if (this.sectionArray.length == 0) {
      this.sectionArray = this._loadCaseService.getLoadsLocalStorage();
      if (!this.sectionArray)
        this.sectionArray = [];
    }
    this.wellModel = this._loadCaseService.getwellsLocalStorage();

    if (this.wellModel == null) {
      this.wellModel = new welldesc();
    }
    this.setEmptyPageMessageAndTitle();
    this.setLoadCount();

    if (this.sectionArray.length > 0) {
      this.wellNameForm = this.sectionArray[0].wellName;
      this.wellName = this.sectionArray[0].wellName;
    }
    if (this.displayTableComponent) {
      this.displayTableComponent.showAddRowInput = false;
    }

  }

  onFileSelected(event) {
    this.uploadedFile = event.target.files[0];

    if (this.uploadedFile.name.split('.').pop() !== 'xlsx' && this.uploadedFile.name.split('.').pop() !== 'csv') {
      this._alertErrorService.errorMessage.next(Constants.LoadsExtensionNotAllowed);
      this.closeSuccessMessage();
      return;
    }

    let specialCharacterError = false;

    let fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      this.sectionArray = [];

      let isUnitOfMeasureALreadySet = false;
      let loadCount = 0;
      let caseDescription: string = '', wellName = '';


      for (let sheetName of workbook.SheetNames) {

        if (specialCharacterError || !isValidTextWithSomeSpecialCharacterRestrictions(sheetName)) {
          specialCharacterError = true;
          break;
        }

        let worksheet = workbook.Sheets[sheetName];
        let section: section = { name: sheetName, wellName: '', loadArray: [] };
        let convertedWorksheet = XLSX.utils.sheet_to_json<ImportExcel>(worksheet, { header: "A" });
        let index = 0;

        let load: load = { name: '', comment: '', sealabilityEnvelopes: [], pressureAndTensionList: [] };

        for (let each of convertedWorksheet) {
          if (index == 0) {
            if (!isValidTextWithSomeSpecialCharacterRestrictions(each.B) || !isValidTextWithSomeSpecialCharacterRestrictions(each.A)) {
              specialCharacterError = true;
              break;
            }
            if (each.A) {
              // Remove "Well Name:" from the template to get the Well Nae
              wellName = each.A.substring(10);
              if (wellName == "undefined")
                wellName = '';
            }

            if (each.B) {
              //Remove "Case Description" from the template to get the Case Description
              caseDescription = each.B.substring(17);
              if (caseDescription == "undefined")
                caseDescription = '';
            }
          }
          if (index > 1) {
            if (each.B && each.B !== "Load Name") {
              loadCount++;
            }

            if (!isValidTextWithSomeSpecialCharacterRestrictions(each.B) || !isValidTextWithSomeSpecialCharacterRestrictions(each.C) || !isValidTextWithSomeSpecialCharacterRestrictions(each.D)
              || !isValidTextWithSomeSpecialCharacterRestrictions(each.E) || !isValidTextWithSomeSpecialCharacterRestrictions(each.F)) {
              specialCharacterError = true;
              break;
            }

            //Load name
            if (each.B) {
              load = { name: each.B, comment: each.C, sealabilityEnvelopes: [], pressureAndTensionList: [] };
            }

            //Sealability Envelopes
            if (each.D) {
              let envelope: sealabilityEnvelope = { checked: true, name: each.D };
              load.sealabilityEnvelopes.push(envelope);
            }

            //Tension and Pressure
            if ((each.E || +each.E === 0) && (each.F || +each.F === 0)) {
              let pressureAndTension: pressureAndTension = { imported: true, tension: +each.E, pressure: +each.F };
              load.pressureAndTensionList.push(pressureAndTension);
            }

            //Unit of measure
            if (each.G && !isUnitOfMeasureALreadySet) {
              this._unitOfMeasure.setUnitOfMeasure(each.G);
              this._unitOfMeasure.setFromUnitOfMeasure(each.G);
              isUnitOfMeasureALreadySet = true;
            }

            if (index == convertedWorksheet.length - 1) {
              section.loadArray.push(load);
              break;
            }

            if (convertedWorksheet[index + 1].B && each.B !== "Load Name") {
              section.loadArray.push(load);
            }

          }
          index++;
        }
        section.wellName = wellName;
        this.sectionArray.push(section);
      }      

      this.updatewellData(wellName, caseDescription);

      if (specialCharacterError) {
        this.sectionArray = [];
        this.showLoad = false;
        this._alertErrorService.errorMessage.next(Constants.SpecialCharacterError);
      }
      else if (this.getLoadCount() !== loadCount) {
        this.showLoad = false;
        this._alertErrorService.errorMessage.next(Constants.LoadImportError);

      }
      else {
        this.successMessage = Constants.LoadsImported;
        this.showLoad = false;
      }

      this.setLoadCount();
      this.setEmptyPageMessageAndTitle();
    }

    fileReader.readAsArrayBuffer(this.uploadedFile);
  }

  resetErrorState() {
    this.loadNameError = false;
    this.sealabilityError = false;
    this.loadNameErrorMessage = '';
    this.loadCommentsError = false;
    this.loadCommentsErrorMessage = '';
  }

  async addLoad(index) {
    this.successMessage = '';
    this.sectionIndexToAddOrEditLoad = index;
    this.load = new load();
    this.showLoad = true;
    this.reloadSealability();
    this.resetErrorState();
    this.resetPressureAndTension();
    this.isToEdit = false;
    this.loadNameForm = '';
    this.loadCommentsForm = '';

    await sleep(100);
    if (this.displayTableComponent) {
      this.displayTableComponent.showAddRowInput = false;
    }
  }

  async editLoad(editLoad: editLoad) {
    this.successMessage = '';
    this.savedEditLoad = deepCopy(editLoad.load);
    this.showLoad = true;
    this.load = editLoad.load;
    this.reloadSealability(this.load);
    this.editLoadSectionIndex = editLoad.sectionIndex;
    this.isToEdit = true;
    this.useAddRow = true;
    this.loadNameForm = this.load.name;
    this.loadCommentsForm = this.load.comment;
    this.reloadLoadPressureAndTensionList();

    await sleep(100);
    if (this.displayTableComponent) {
      this.displayTableComponent.showAddRowInput = false;
    }
  }

  reloadLoadPressureAndTensionList() {
    this.pressureAndTensionLoadList = [];

    if (this.load && this.load.pressureAndTensionList) {
      this.load.pressureAndTensionList.forEach((pressureAndTension) => {

        if (pressureAndTension.tension || pressureAndTension.pressure) {
          let tableRow: ITableRow = {
            editable: true, tdColumn: [{ editable: false, errorMessage: '', headerName: 'Tension', value: pressureAndTension.tension }, { editable: false, errorMessage: '', headerName: 'Pressure', value: pressureAndTension.pressure }], colspan: 1
          };

          tableRow.editable = !pressureAndTension.imported;
          this.pressureAndTensionLoadList.push(tableRow);
        }
      });
    }

  }

  areArraysEqual(arr1: pressureAndTension[], arr2: pressureAndTension[], validateOnlyLoads: boolean): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      const list1 = arr1[i];
      const list2 = arr2[i];
  
      if (list1.pressure !== list2.pressure || list1.tension !== list2.tension) {
        return false;
      }
    }
  
    return true;
  }

currentLoadName: string;
currentLoadComments: any;

saveLoad(loadName, loadComments) {
  this.currentLoadName = loadName;
  this.currentLoadComments = loadComments;
    if(this.arePressureValueTensionModifiedAgainstDP(loadName)){
      this.showPressureTensionModifiedModelForDP = true;
    }else{
      this.handleSaveLoad(loadName,loadComments);
    }
}
  

  handleSaveLoad(loadName, loadComments){
    this.resetErrorState();
    if (!loadName) {
      this.loadNameErrorMessage = Constants.LoadNameEmpty;
      this.loadNameError = true;
    }

    var isValidLoadName = isValidTextWithSomeSpecialCharacterRestrictions(loadName);
    var isValidLoadComments = isValidTextWithSomeSpecialCharacterRestrictions(loadComments);

    if (!isValidLoadName || !isValidLoadComments) {
      if (!isValidLoadName) {
        this.loadNameErrorMessage = Constants.SpecialCharacterError;
        this.loadNameError = true;
      }

      if (!isValidLoadComments) {
        this.loadCommentsError = true;
        this.loadCommentsErrorMessage = Constants.SpecialCharacterError;
      }

    }

    if (this.isToEdit) {
      let editNameChanged = (this.isToEdit && this.load.name.toLowerCase() !== loadName.toLowerCase());

      if (editNameChanged && this.sectionArray[this.sectionIndexToAddOrEditLoad].loadArray.filter(l => l.name.toLowerCase() == loadName.toLowerCase()).length > 0) {
        this.loadNameErrorMessage = Constants.LoadNameNotUnique;
        this.loadNameError = true;
      }
    }
    else {
      if (this.sectionArray[this.sectionIndexToAddOrEditLoad].loadArray.filter(l => l.name.toLowerCase() == loadName.toLowerCase()).length > 0) {
        this.loadNameErrorMessage = Constants.LoadNameNotUnique;
        this.loadNameError = true;
      }
    }


    if (this.load.sealabilityEnvelopes.filter(p => p.checked).length == 0) {
      this.sealabilityError = true;
    }

    if (this.pressureAndTensionLoadList.find(p => p.tdColumn.find(x => x.errorMessage !== null && x.errorMessage !== ''))) {
      return;
    }

    if (!this.loadNameError && !this.sealabilityError && !this.wellNameError && !this.loadCommentsError) {
      this.load.name = loadName;
      this.load.comment = loadComments;
      this.successMessage = Constants.LoadSaved;

      if (!this.isToEdit) {
        this._measureService.logUsage("Load Created").subscribe();
        this.sectionArray[this.sectionIndexToAddOrEditLoad].loadArray.push(this.load);
        this.successMessage = Constants.LoadCreated;
      }
      this.showLoad = false;
    }
    this.setEmptyPageMessageAndTitle();
    this.setLoadCount();
  }


  private arePressureValueTensionModifiedAgainstDP(loadName: any) {
    let isPressureTensionValuesModified = false;
    if (this._loadCaseService.isFromDrillPlan) {

      const loadsSections = this._loadCaseService.getLoadsLocalStorageForDrillPlanCase();
      if (loadsSections && loadsSections.length > 0) {
        const drillPlanSection = loadsSections[0]; // as drillplan returns only one section
        const drillPlanLoadPressureTensionData = drillPlanSection.loadArray.find(load => load.name === loadName)?.pressureAndTensionList;

        if (drillPlanLoadPressureTensionData) {
          const currentPressureTensionList = this.pressureAndTensionLoadList.map((item: ITableRow) => {
            const pressure = item.tdColumn.find(p => p.headerName.startsWith('Pressure'))?.value;
            const tension = item.tdColumn.find(p => p.headerName.startsWith('Tension'))?.value;

            return { pressure, tension, imported: false };
          }).filter(item => item.pressure !== undefined && item.tension !== undefined);

          isPressureTensionValuesModified = !this.areArraysEqual(currentPressureTensionList, drillPlanLoadPressureTensionData, true);
        }
      }
    }
    return isPressureTensionValuesModified;
  }

  onCheckboxChange(event: any, name: string) {
    var checkboxValue = this.load.sealabilityEnvelopes.find(obj => { return obj.name == name });
    checkboxValue.checked = event.target.checked;
  }

  reloadSealability(load: load = null) {
    var sealabilityTypes = this._loadCaseService.getEnvelopesType();
    let sealabilityItems: sealabilityEnvelope[] = [];
    sealabilityTypes.forEach(function (data) {
      var obj: sealabilityEnvelope = { name: data, checked: false };
      if (load != null) {
        obj.checked = load.sealabilityEnvelopes.find(p => p.name.toLowerCase() == data.toLowerCase()) ? load.sealabilityEnvelopes.find(p => p.name.toLowerCase() == data.toLowerCase()).checked : false;
      }
      sealabilityItems.push(obj);
    });
    this.load.sealabilityEnvelopes = sealabilityItems;
  }

  resetPressureAndTension() {
    this.load.pressureAndTensionList = [];
    this.pressureAndTensionLoadList = [];
  }

  resetAction() {
    if (!this.isToEdit) {
      this.reloadSealability();
      this.resetPressureAndTension();
      this.load = new load();
      this.loadNameForm = '';
    }
    else {
      this.load = deepCopy(this.savedEditLoad);
      this.loadNameForm = this.load.name;
      this.reloadLoadPressureAndTensionList();
    }

    this.displayTableComponent.showAddRowInput = false;
    this.displayTableComponent.isToShowCopy = false;

    this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
  }

  focusOutWell(wellname) {
    if (!isValidTextWithSomeSpecialCharacterRestrictions(wellname)) {
      this.wellNameError = true;
      return;
    }
    this.wellNameError = false;
    if (this.sectionArray.length > 0) {
      this.sectionArray[0].wellName = wellname;
      this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
    } else {
      this.wellName = wellname;
    }
  }

  addPressureAndTension() {
    this.load.pressureAndTensionList = [];
    this.pressureAndTensionLoadList.forEach((item) => {
      this.load.pressureAndTensionList.push({ pressure: item.tdColumn.find(p => p.headerName.startsWith('Pressure')).value, tension: item.tdColumn.find(p => p.headerName.startsWith('Tension')).value, imported: false });
    });
  }

  deletePressureAndTension(index: number) {
    this.load.pressureAndTensionList.splice(index, 1);
    this.reloadLoadPressureAndTensionList();
  }

  cancel() {
    this.resetErrorState();
    this.resetPressureAndTension();
    this.showLoad = false;
  }

  openDeleteLoadConfirmationModal() {
    this.loadTitleConfirmationText = 'Delete ' + this.load.name;
    this.loadConfirmationText = Constants.LoadDeleteConfirmation.replace("{{loadname}}", this.load.name);
    this.loadConfirmationModalOpen = true;
  }

  deleteLoad(isToDelete: boolean) {
    this.loadConfirmationModalOpen = false;
    if (isToDelete) {
      let section = this.sectionArray[this.editLoadSectionIndex];
      let loadIndex = section.loadArray.findIndex(p => p.name.toLowerCase() == this.load.name);
      section.loadArray.splice(loadIndex, 1);
      this.setLoadCount();
    }
    this.cancel();
    this.showLoad = false;
    this.setEmptyPageMessageAndTitle();
  }
  deleteLoadFromEvent(deleteLoad: editLoad) {
    this.successMessage = '';
    this.load = deleteLoad.load;
    this.editLoadSectionIndex = deleteLoad.sectionIndex;
    this.deleteLoad(true);
  }

  closeSuccessMessage() {
    this.successMessage = '';
  }

  sectionAdded() {
    this.successMessage = Constants.SectionCreated;
    this.sectionArray[this.sectionIndexToAddOrEditLoad].wellName = this.wellName;
    this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
    this.setEmptyPageMessageAndTitle();
  }

  sectionDeleted() {
    this.setEmptyPageMessageAndTitle();
  }

  convertSectionArrayIntoExportToExcel() {
    this.isBlankExcelTemplate = false;
    let exportToExcel: IExportToExcel[] = [];

    if (this.sectionArray.length > 0) {
      this.sectionArray.forEach((section) => {
        let sheet: IExportToExcel = {
          sheetName: section.name, headerList: ['', 'Load Name', 'Load Comments', 'Sealability Envelopes', `${this.pressureAndTensionHeader[0].name}`, `${this.pressureAndTensionHeader[1].name}`, 'UnitOfMeasure'], rows: [], wellDetailList: ['Well Name:' + this.wellModel.wellName, 'Case Description:' + this.wellModel.caseDescription]
        };
        let index = 0;
        let loadIndex = 0;

        section.loadArray.forEach((load) => {
          let sealabilityEnvelopesChecked = load.sealabilityEnvelopes.filter(p => p.checked);
          let maxLength: number = sealabilityEnvelopesChecked.length > load.pressureAndTensionList.length ? sealabilityEnvelopesChecked.length : load.pressureAndTensionList.length;

          for (var i = 0; i < maxLength; i++) {
            let formula = index >= 1 ? `=IF(OR(OR(NOT(ISBLANK(C${(index + 1)})),NOT(ISBLANK(D${(index + 1)})),NOT(ISBLANK(E${(index + 1)}))),OR(NOT(ISBLANK(C${index + i})),NOT(ISBLANK(D${index})),NOT(ISBLANK(E${index}))) ),ROW()-1,"")` : '';

            let pressureValue = load.pressureAndTensionList.length > i ? load.pressureAndTensionList[i].pressure.toString() : '';
            let tensionValue = load.pressureAndTensionList.length > i ? load.pressureAndTensionList[i].tension.toString() : '';
            let sealabilityValue = sealabilityEnvelopesChecked.length > i ? sealabilityEnvelopesChecked[i].name : '';

            let exportToExcelRow: IExportToExcelRow = {
              columns: [{ formula: formula }, { content: i == 0 ? load.name : '' }, { content: i == 0 ? load.comment : '' }, { content: sealabilityValue }, { content: tensionValue }, { content: pressureValue }, { content: i == 0 && loadIndex == 0 ? this._unitOfMeasure.getUnitOfMeasure() : '' }]
            };
            sheet.rows.push(exportToExcelRow);
            index++;
            loadIndex++;
          }
          sheet.rows.push({ columns: [{ formula: `=IF(OR(OR(NOT(ISBLANK(C${(index + 1)})),NOT(ISBLANK(D${(index + 1)})),NOT(ISBLANK(E${(index + 1)}))),OR(NOT(ISBLANK(C${index})),NOT(ISBLANK(D${index})),NOT(ISBLANK(E${index}))) ),ROW()-1,"")` }] });
          loadIndex++;
          index++;
        });

        for (let i = index; i < 500; i++) {
          sheet.rows.push({ columns: [{ formula: `=IF(OR(OR(NOT(ISBLANK(C${(i + 1)})),NOT(ISBLANK(D${(i + 1)})),NOT(ISBLANK(E${(i + 1)}))),OR(NOT(ISBLANK(C${i})),NOT(ISBLANK(D${i})),NOT(ISBLANK(E${i}))) ),ROW()-1,"")` }] });
        }

        exportToExcel.push(sheet);
      });
    }
    else {
      //When no section is available, generate blank template with default load and section.
      this.isBlankExcelTemplate = true;
      exportToExcel = this._loadCaseService.generateBlankTemplateExportToExcel();
    }
    return exportToExcel;
  }

  getLoadSealability(load: load) {
    let result: string = '';
    load.sealabilityEnvelopes.forEach((envelope) => {
      result += envelope.checked ? envelope.name + ';' : '';
    });
    return result.substring(0, result.length - 1);
  }

  getLoadPressureAndTension(load: load) {
    let result: string = '';
    let index: number = 0;
    load.pressureAndTensionList.forEach((pressureAndTension) => {
      result += pressureAndTension.pressure + ';' + pressureAndTension.tension;
      if (index < load.pressureAndTensionList.length - 1) {
        result += '|';
      }
      index++;
    });
    return result;
  }

  async exportLoadToExcel() {
    this.excelExport = this.convertSectionArrayIntoExportToExcel();
    // wait for angular creates the table with updated values before exporting it
    await sleep(100);
    if (this.wellModel.wellName !== '' && this.wellModel.wellName != undefined) {
      this.exportToExcelComponent.fileName = "loads_" + this.wellModel.wellName + ".xlsx";
    }
    else {
      this.exportToExcelComponent.fileName = "loads.xlsx";
    }
    this.exportToExcelComponent.exportToExcel();
    this.successMessage = Constants.LoadsExported;
    this.showLoad = false;
  }

  importLoad(isToImport: boolean) {
    this.showImportConfirmationModal = false;
    if (isToImport) {
      let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
      element.click();
      this._measureService.logUsage("Load imported").subscribe();
    }
  }

  importConfirmationModal() {
    if (this.sectionArray.length > 0) {
      this.showImportConfirmationModal = true;
    }
    else {
      this.importLoad(true);
    }
  }

  reloadSectionWithUOM() {
    if (this.sectionArray.length > 0 && this.sectionArray[0] != null && this.sectionArray.find(p => p.loadArray.length > 0) != null)
      this._loadCaseService.getSectionsConvertedByUOM(this.sectionArray).subscribe(responseData => {
        this.sectionArray = responseData;
        if (this.showLoad && this.load && this.isToEdit) {
          this.load = this.sectionArray[this.editLoadSectionIndex].loadArray.find(p => p.name == this.load.name);
          this.reloadLoadPressureAndTensionList();
          this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
        }
      });
  }

  reloadLoadWithUOM() {
    if (this.load != null)
      this._loadCaseService.getConvertedDataForPressureAndTensionLoad(this.load).subscribe(responseData => {
        this.load = responseData;
        this.reloadLoadPressureAndTensionList();
        this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
      });

  }

  reloadHeaderUOM() {
    if (this.load != null) {
      this._loadCaseService.getAcronymForPressureTension().subscribe(responseData => {
        let pressureAcronym = responseData.data.find(p => p.name.toLowerCase() == 'pressure').unitAcronym;
        let tensionAcronym = responseData.data.find(p => p.name.toLowerCase() == 'tension').unitAcronym;

        if (pressureAcronym && tensionAcronym) {
          this.pressureAndTensionHeader = [];
          this.pressureAndTensionHeader.push({ name: `Tension (${tensionAcronym})`, isNumeric: true, errorMessage: '' });
          this.pressureAndTensionHeader.push({ name: `Pressure (${pressureAcronym})`, isNumeric: true, errorMessage: '' });

        }
      });

      this.pressureAndTensionHeader.push({ name: `Tension (klbf)`, isNumeric: true, errorMessage: '' });
      this.pressureAndTensionHeader.push({ name: `Pressure (psi)`, isNumeric: true, errorMessage: '' });
    }
  }

  setLoadCount() {
    let loadCount = this.getLoadCount();
    this._mainHeaderService.UpdateLoadCount(loadCount);
    this._loadCaseService.saveLoadsLocalStorage(this.sectionArray);
  }

  getLoadCount() {
    let loadCount = 0;
    this.sectionArray.forEach(section => {
        loadCount += section.loadArray.length;
      });    
    return loadCount;
  }

  addSectionFromLandingPage() {
    this.sectionComponent.openSectionModal();
  }

  setEmptyPageMessageAndTitle() {
    this.loadEmptyTitle = this.sectionArray.length == 0 ? Constants.LoadEmptyMessageEmptySectionTitle : Constants.LoadEmptyMessageTitle;

    if (this.sectionArray.length == 0)
      this.loadEmptyMessage = Constants.LoadEmptyMessageEmptySectionMessage;
    else if (this.sectionArray.find(p => p.loadArray.length > 0)) {
      this.loadEmptyMessage = Constants.LoadEmptyMessage;
    }
    else {
      this.loadEmptyMessage = Constants.LoadEmptyWithNoLoadsMessage;
    }
    this.isToShowButtonsInLandingPage = this.sectionArray.length == 0;
  }

  openSectionModalForWell() {
    this.sectionModalOpenForWell = true;
  }

  openSectionModalForWellEdit() {
    this.wellModel = this._loadCaseService.getwellsLocalStorage();
    this.isToEdit = true;
    this.sectionModalOpenForWell = true;
  }

  closeSectionModalForWell() {
    this.sectionModalOpenForWell = false;
  }

  createOrEditWellDesc(wellName: string, caseDescription: string, isToEdit: boolean) {
    this.wellNameError = false;
    this.caseDescriptionError = false;

    let isValidWellName = isValidTextWithSomeSpecialCharacterRestrictions(wellName);
    let isValidCaseDescription = isValidTextWithSomeSpecialCharacterRestrictions(caseDescription);

    if (!isValidCaseDescription || !isValidWellName) {
      if (!isValidWellName) {
        this.wellNameError = true;
        this.wellNameErrorMessage = Constants.SpecialCharacterError;
      }

      if (!isValidCaseDescription) {
        this.caseDescriptionError = true;
        this.caseDescriptionErrorMessage = Constants.SpecialCharacterError;
      }

      return;
    }

    if (isToEdit) {
      this.updatewellData(wellName, caseDescription);
      this.closeSectionModalForWell();
    }
    else if (!isToEdit) {
      this.updatewellData(wellName, caseDescription);
      this.closeSectionModalForWell();
    }
  }

  updatewellData(name: string, desc: string) {
    this.wellModel = new welldesc();
    this.wellModel.wellName = name;
    this.wellModel.caseDescription = desc;
    this._loadCaseService.savewellsLocalStorage(this.wellModel);
  }

  onWellActionClick(actionValue, name: string) {
    switch (actionValue) {
      case 'Edit':
        this.openSectionModalForWellEdit();
        break;
      case 'Delete':
        this.openWellConfirmationModal();
        break;
      default:
    }
  }

  openWellConfirmationModal() {
    this.wellTitleConfirmationText = 'Delete well details';
    this.wellConfirmationText = Constants.WellDeleteConfirmation;
    this.wellConfirmationModalOpen = true;
  }

  deleteWellDetails(isToDelete: boolean) {
    this.wellConfirmationModalOpen = false;
    if (isToDelete) {
      this.wellModel = new welldesc();
      this.isToEdit = false;
      this._loadCaseService.savewellsLocalStorage(this.wellModel);
    }
  }

saveLoadOnConfirmationForDrillPlanCase(isConfirmed: boolean) {
    this.showPressureTensionModifiedModelForDP = false;
    if (isConfirmed) {
     this.handleSaveLoad(this.currentLoadName, this.currentLoadComments);
    }
  }
}
