<form [formGroup]="detailsForm">
    <div style="padding-left:20px">

        <!-- General -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    General
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell details-headers">
                                                Manufacturer <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="manufacturerlist"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_MANUF_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Connection Name <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_CNCT_NM"
                                                    type="text">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Connection Type <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="connectionTypeList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_CNCT_GEOM_TYPE_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Weight
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_NOM_PIPE_WT"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.weightUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser" class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Grade <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="gradeList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_FULL_CNCT_GRD_ID]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Gas Envelope
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text" disabled
                                                    [value]="connectionDescCustomFields?.gasEnvelopePresence"
                                                    class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Active
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_ACTIVE_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Document Number
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_RPT_ID"
                                                    type="text">
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Stewarding Company <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="stewardingCompanyList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_DATA_SRC_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Last Updated
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_CHNG_DATE"
                                                    type="text" [disabled]="true" class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Modified By
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_SealShoulderInformation_ModifiedBy"
                                                    type="text" [disabled]="true" class="additional-columns-background-disabled">
                                            </td>
                                        </tr>

                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Pipe Details -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Pipe Details
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Size (OD) <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_NOM_PIPE_OD_DIAM"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.odUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Wall Thickness <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_NOM_PIPE_WALL_THCK"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.wallThicknessUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Yield
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_NOM_PIPE_YLD_STRGTH"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.yieldUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Material
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text" disabled
                                                    [value]="connectionDescCustomFields?.material"
                                                    class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                CRA Flag <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_CRA_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Drawing Numbers -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Drawing Numbers
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Box
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_BOX_CPL_DRW_NBR"
                                                    type="text">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Pin
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_PIN_DRW_NBR"
                                                    type="text">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Thread
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_THRD_PROF_DRW_NBR"
                                                    type="text">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Details
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_DTL_DRW_NBR"
                                                    type="text">
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Connection Details -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Connection Details
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Coupling/Box OD
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_NOM_BOX_CPL_OD"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.odUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Coupling/Box Length
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_NOM_BOX_CPL_LNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.odUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Make-Up Loss
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_MU_LOSS_LEN"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.odUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Connection Use
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text" disabled
                                                    [value]="connectionDescCustomFields?.connectionUse"
                                                    class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Thread Compound Comment
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="threadCompoundTypeList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_THRD_CMPND_APPL_TXT]"></app-resuabledropdown>
                                            </td>
                                        </tr>                                        
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Pipe Critical Cross-Section
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text"
                                                    [formControlName]="connectionDescriptionEnums.e_PIPE_CRTC_XSA"
                                                    appNumericInput>
                                                <span>{{unitMeasures.areaUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Variable Length Box
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <!-- <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_VAR_LNG_BOX_CPL_FLG]"></app-resuabledropdown> -->
                                                    <input type="text"
                                                    [value]="connectionDescCustomFields?.variableLengthBox" 
                                                    disabled
                                                    class="additional-columns-background-disabled">
                                                    
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Box Critical Cross-Section <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text"
                                                    [formControlName]="connectionDescriptionEnums.e_BOX_CPL_CRTC_XSA"
                                                    appNumericInput>
                                                <span>{{unitMeasures.areaUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Pin Critical Cross-Section <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text"
                                                    [formControlName]="connectionDescriptionEnums.e_PIN_CRTC_XSA"
                                                    appNumericInput>
                                                <span>{{unitMeasures.areaUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Thread Geometry Type
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="threadGeometryTypeList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_THRD_GEOM_TYPE_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Thread Type
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="threadTypeList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_THRD_TYPE_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Multi-Step Thread
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_MULT_STEP_THRD_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Use Default Folder
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_USE_DFLT_FLDR_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                          <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                            Connection Document Link
                                          </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text"
                                                    [formControlName]="connectionDescriptionEnums.e_DOC_LINK" [disabled]="true"
                                                    class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>


        <!-- Test Facts -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Test Facts
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div *ngIf="this.tabStorageService.adminUser" class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Connection Analysis Date <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_CNCT_ANAL_DATE"
                                                    type="date" class="tcdb-connection-analysis-date">
                                            </td>
                                        </tr>                                        
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Tubing Qualification <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_TBG_QLF_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Temperature Rating
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                              <input [formControlName]="connectionDescriptionEnums.e_TEMP_RTNG"
                                                     type="text" appNumericInput>
                                              <span>{{unitMeasures.temperatureUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                M&B Cycles
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_MB_CYC_CNT"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Internal Quench Test
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_INTRNL_QNCH_TEST_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                API Pressure Rating
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <!-- <input type="text"
                                                    [formControlName]="connectionDescriptionEnums.e_API_PRSR_RTNG_FLG"> -->
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.API_PRSR_RTNG_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Seal Test
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.SEAL_TEST_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                External Seal Test
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.EXTDN_SEAL_TEST_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                External Pressure Test
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_EXT_PRSR_TEST_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Thermal Cycle Test
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_THRM_CYC_TEST_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Structural Test
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_STRUCT_TEST_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>



        <!-- Surface Finish -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Surface Finish
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Pin
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="pinList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_PIN_COAT_TYPE_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Coupling/Box
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="couplingBoxList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_BOX_CPL_COAT_TYPE_NM]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Pin Thread Compound Weight
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_RCMD_PIN_THRD_CMPND_WT"
                                                    type="text" appNumericInput>
                                              <span> g</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Box Thread Compound Weight
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                              <input [formControlName]="connectionDescriptionEnums.e_RCMD_BOX_CPL_THRD_CMPND_WT"
                                                     type="text" appNumericInput>
                                              <span> g</span>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Evaluation -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Evaluation
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Evaluation Status
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text" disabled
                                                    [value]="connectionDescCustomFields?.evaluationStatus"
                                                    class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Evaluation Method
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text" disabled
                                                    [value]="connectionDescCustomFields?.evaluationMethod"
                                                    class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                          <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                            Productline / Derived Envelope
                                          </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.PRDCTLN_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Fatigue Evaluation
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.Fatigue_Evaluation]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Limited Sealability Envelope
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.Limited_Sealability_Envelope]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Bleed Through
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.Bleed_Through]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Unconventional
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.Unconventional]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                FEA Only
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.FEA_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Fully Evaluated
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptionsForNullables"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.FULLEVAL_FLG]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Torques -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Torques ({{unitMeasures.torqueUOM}})
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Final Min
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_RCMD_MIN_MU_TRQ"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Final Max
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Final Optimum
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input type="text"
                                                    [formControlName]="connectionDescriptionEnums.e_RCDM_OPT_MU_TRQ"
                                                    appNumericInput>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Shoulder Min
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_RCMD_MIN_SHLDR_TRQ"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Shoulder Max
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_RCMD_MAX_SHLDR_TRQ"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Yield Torque
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_YLD_TRQ"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <tr *ngIf="this.tabStorageService.adminUser"
                                            class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                High Torque
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="dropDownOptions"
                                                    [control]="detailsForm?.controls[dropdownTypeAdditionalDetailSectionControlNames.High_Torque]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Field Torque
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_FLD_TRQ"
                                                    type="text" appNumericInput>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Manufacturer's Rating -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Manufacturer's Rating
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Tension - Pipe Body
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_PIPE_TENS_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.tensionUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Tension - Connection
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_CNCT_TNSN_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.tensionUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Tension Efficiency <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_NOM_TNSL_EFC_PCT"
                                                    type="text" appNumericInput>
                                                <span>%</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Compression - Pipe Body
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_PIPE_CMPRS_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.tensionUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Compression - Connection
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input [formControlName]="connectionDescriptionEnums.e_CNCT_CMPRS_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.tensionUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Compression - Efficiency <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_NOM_CMPRS_EFC_PCT"
                                                    type="text" appNumericInput>
                                                <span>%</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Internal Pressure - Pipe Body
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_PIPE_INTRNL_PRSR_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.pressureUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Internal Pressure - Connection
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_CNCT_INTRNL_PRSR_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.pressureUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Internal Pressure - Efficiency <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_NOM_INTRNL_PRSR_EFC_PCT"
                                                    type="text" appNumericInput>
                                                <span>%</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                External Pressure - Pipe Body
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_PIPE_EXT_PRSR_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.pressureUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                External Pressure - Connection
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_CNCT_EXT_PRSR_RTNG"
                                                    type="text" appNumericInput>
                                                <span>{{unitMeasures.pressureUOM}}</span>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row ">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                External Pressure - Efficiency <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_NOM_EXT_PRSR_EFC_PCT"
                                                    type="text" appNumericInput>
                                                <span>%</span>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Seal and Shoulder Information -->
        <section class="em-c-section " *ngIf="this.tabStorageService.adminUser">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Seal and Shoulder Information
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Seal Type Name <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="sealTypeList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_Seal_Type_Name]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Seal Location Name <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="sealLocationList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_Seal_Location_Name]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Shoulder Location Name <span class="redast">*</span>
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <app-resuabledropdown [items]="shoulderLocationList"
                                                    [control]="detailsForm?.controls[connectionDescriptionEnums.e_Shoulder_Location_Name]"></app-resuabledropdown>
                                            </td>
                                        </tr>
                                        <!-- Commenting these lines as we hiding -->
                                        <!-- <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Modified By
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_SealShoulderInformation_ModifiedBy"
                                                    type="text" disabled class="additional-columns-background-disabled">
                                            </td>
                                        </tr>
                                        <tr class="em-c-table__row additional-columns-background">
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable details-headers">
                                                Change Date
                                            </td>
                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="2">
                                                <input
                                                    [formControlName]="connectionDescriptionEnums.e_SealShoulderInformation_ChangeDate"
                                                    type="text" disabled class="additional-columns-background-disabled">
                                            </td>
                                        </tr> -->
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>



        <!-- Test Comments -->
        <section class="em-c-section ">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Test Comments
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row ">

                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="3">
                                                <textarea [formControlName]="connectionDescriptionEnums.e_TEST_CMNT_TXT"
                                                    class="tcdb-test-comments" rows="10" cols="30"></textarea>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Additional Qualification Comments -->
        <section class="em-c-section additional-columns-background additional-columns-background"
            *ngIf="this.tabStorageService.adminUser">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Additional Qualification Comments
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row additional-columns-background">

                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="3">
                                                <textarea [formControlName]="connectionDescriptionEnums.e_TEST_SMPL_TXT"
                                                    class="tcdb-test-comments" rows="10" cols="30"></textarea>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>

        <!-- Administrative Comments -->
        <section class="em-c-section additional-columns-background" *ngIf="this.tabStorageService.adminUser">
            <header class="em-c-section__header em-c-section__header--underline">
                <h2 class="em-c-section__title ">
                    Administrative Comments
                    <span class="em-c-section__title-underline"></span>
                </h2>
            </header>
            <!-- end em-c-section-header -->
            <div class="em-c-section__body">
                <div style="padding-left:20px">
                    <div class="em-c-table-object ">
                        <div class="em-c-table-object__header">
                        </div>
                        <!--end em-c-table-object__header-->
                        <div class="em-c-table-object__body">
                            <div class="em-c-table-object__body-inner">
                                <table class="em-c-table ">
                                    <!-- end em-c-table__header -->
                                    <tbody class="em-c-table__body ">
                                        <tr class="em-c-table__row additional-columns-background">

                                            <td class="em-c-table__cell em-js-cell em-js-cell-editable" colspan="3">
                                                <textarea
                                                    [formControlName]="connectionDescriptionEnums.e_Administrative_Comments"
                                                    class="tcdb-test-comments" rows="10" cols="30"></textarea>
                                            </td>
                                        </tr>
                                        <!-- end em-c-table__row -->
                                    </tbody>
                                    <!-- end em-c-table__body -->
                                    <tfoot class="em-c-table__footer">
                                        <tr class="em-c-table__footer-row">
                                        </tr>
                                    </tfoot>
                                    <!-- end em-c-table__footer -->
                                </table>
                                <!--end em-c-table-->
                            </div>
                            <!--end em-c-table-object__body-inner-->
                        </div>
                        <!--end em-c-table-object__body-->
                    </div>
                </div>
                <!--end em-c-table-object-->
                <!--end em-c-table-object-->
            </div>
            <!-- end em-c-section__body -->
        </section>


    </div>
    <!-- end em-c-section -->
</form>
