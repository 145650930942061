import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IReusableComponent } from '../../IResusableComponent';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-resuabledropdown',
  templateUrl: './resuabledropdown.component.html',
  styleUrls: ['./resuabledropdown.component.css']
})
export class ResuabledropdownComponent{
  @Input() items: string[];
  @Input() control = new FormControl();
  @Input() id: string;

  constructor() { }

}
