import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndPoints } from './app-config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private endpoint = `${EndPoints.API_BASE_URL}/Upload/shareFileToDrillPlan`;

  constructor(private http: HttpClient) { }

  shareFileToDrill(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(this.endpoint, formData, {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data'
      })
    });
  }
}
