import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { ITableHeader } from '../../../../../common/models/components/iTableHeader';
import { IColumn, ITableRow } from '../../../../../common/models/components/iTableRow';
import { IConnectionPerformance } from '../../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';
import { IVTableDataMapper } from '../../../../../common/models/responses/interfaces/IVTableDataMapper.interface';
import { UnitOfMeasureModel } from '../../../../../common/models/unitOfMeasureModel';
import { LoadCaseService } from '../../.././../../service/tcdb/loadcaseservice';
import { UnitOfMeasureService } from '../../.././../../service/tcdb/unitofmeasure.service';
import { TabStorageService } from '../../../../../service/tcdb/tabstorage.service';
import { UploadUtilityService } from '../../../pages/upload-utility/upload-utility.service';
import { AXIAL_LOAD, CHNG_DATE, CNCT_ID, ConnectionPerformacneTable, ConnectionPerformance_AxialLoads, ConnectionPerformance_AxialLoads_Column, ConnectionPerformance_ChangeDate, ConnectionPerformance_ChangeDate_Column, ConnectionPerformance_EnvlpType, ConnectionPerformance_EnvlpType_Column, ConnectionPerformance_ModUid, ConnectionPerformance_ModUid_Column, ConnectionPerformance_NetPrsr, ConnectionPerformance_NetPrsr_Column, ConnectionPerformance_PlotSeqnbr, ConnectionPerformance_PlotSeqnbr_Column, envlP_TYPE_CD, ENVLP_TYPE_CD, ENVLP_TYPE_VTBLs, MOD_UID, NET_PRSR, PLOT_SEQ_NBR, variableSpecifications } from '../upload-utility-helper';
import { AlertErrorService } from '../../../../../service/tcdb/alert-error.service';


@Component({
  selector: 'app-connection-performance-section',
  templateUrl: './connection-performance-section.component.html',
  styleUrls: ['./connection-performance-section.component.css']
})
export class ConnectionPerformanceSectionComponent implements OnInit, OnDestroy {

  @Input() isTableColumnsEditable: boolean = true;
  @Input() connectionId: number;
  pressureUOM: string = 'psi';
  axialLoadTensionUOM: string = 'klbf';
  cpVariables = [NET_PRSR, AXIAL_LOAD, PLOT_SEQ_NBR
  ];

  rowUpdated() {
    this.utilityService.connectionPerformanceSectionRowUpdated.next(true);
  }
  procedureVTableDataForUOM: IVTableDataMapper[] = [];

  connectionInformationHeader: ITableHeader[] = this.getTableHeaderData(this.procedureVTableDataForUOM);
  @Input() connectionPerformances: IConnectionPerformance[];
  @Input() isConnectionEdit: boolean;
  private subscriptions: Subscription[] = [];

  connectionInformationColumnsData: ITableRow[] = [];
  isToEdit = false;
  connectionPerformananceSpecificKey: string;
  columnToDisable: string[] = [];
  constructor(public utilityService: UploadUtilityService, private tabStorageService: TabStorageService, private unitOfMeasureService: UnitOfMeasureService, private loadCaseService: LoadCaseService,
    private cdr: ChangeDetectorRef, private _alertErrorService: AlertErrorService
  ) {
    this.connectionPerformananceSpecificKey = 'cpSpecificKeyToSave_' + this.tabStorageService.getTabId();
    this.columnToDisable.push(CHNG_DATE);
    this.columnToDisable.push(MOD_UID);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
  public setTableHeaderData(procedureVTableData: IVTableDataMapper[]): void {

    //procedureVTableData.unshift({ value: '', label: '' });
    this.procedureVTableDataForUOM = procedureVTableData;
    this.handleNewEnvelopeTypeAddition();
    this.connectionInformationHeader = this.getTableHeaderData(this.procedureVTableDataForUOM);
    this.updateEvelopeTypeCD();
  }
  public getTableHeaderData(procedureVTableData: IVTableDataMapper[]): ITableHeader[] {
    return [
      {
        name: ConnectionPerformance_EnvlpType, isNumeric: false, errorMessage: ''
        , headerPropertyName: ENVLP_TYPE_CD, isRequired: true, isDropdown: true,
        options: procedureVTableData
      },
      { name: ConnectionPerformance_NetPrsr + ` (${this.pressureUOM})`, isNumeric: true, errorMessage: '', headerPropertyName: NET_PRSR},
      { name: ConnectionPerformance_AxialLoads + ` (${this.axialLoadTensionUOM})`, isNumeric: true, errorMessage: '', headerPropertyName: AXIAL_LOAD },
      { name: ConnectionPerformance_PlotSeqnbr, isNumeric: true, errorMessage: '', headerPropertyName: PLOT_SEQ_NBR, isRequired: true },
      { name: ConnectionPerformance_ModUid, isNumeric: false, errorMessage: '', headerPropertyName: MOD_UID },
      { name: ConnectionPerformance_ChangeDate, isNumeric: false, errorMessage: '', isDate: true, headerPropertyName: CHNG_DATE }
    ];
  }

  ngOnInit(): void {
    this.utilityService.getVTableDataByTableNames([ENVLP_TYPE_VTBLs]).subscribe(data => {
      this.setTableHeaderData(this.utilityService.mapToDropdownOptions(data?.vTableData[ENVLP_TYPE_VTBLs], envlP_TYPE_CD, envlP_TYPE_CD));
    });
    if (!this.utilityService.isCSEUpload) {
      sessionStorage.setItem(this.connectionPerformananceSpecificKey, JSON.stringify([]));
    }
    this.handleCSEFileFlow();
    this.utilityService.connectionPerformanceLoaded.next(true);
    this.setConnectonPRMs(this.connectionPerformances);
    if (this.utilityService.isCSEUpload) {
      this.convertUOMAndReloadHeader();
    }

    this.subscriptions.push(
      this.unitOfMeasureService.savedUnitOfMeasure.subscribe((changedUOM: UnitOfMeasureModel) => {
        if (changedUOM) {
          this.convertUOMAndReloadHeader();
        }
      }));
  }




  private handleCSEFileFlow() {
    if (!this.utilityService.isCSEUpload) {
      return;
    }
    this.subscriptions.push(this.utilityService.connectionPerformanceSectionData.subscribe(data => {
      if (!data) {
        return;
      }
      this.connectionInformationColumnsData = [];
      const headers = [
        CNCT_ID,
        ConnectionPerformance_EnvlpType_Column,
        ConnectionPerformance_NetPrsr_Column,
        ConnectionPerformance_AxialLoads_Column,
        ConnectionPerformance_PlotSeqnbr_Column,
        ConnectionPerformance_ModUid_Column,
        ConnectionPerformance_ChangeDate_Column
      ];

      const excludeHeaders = [CNCT_ID];

      const filteredHeaders = headers.filter(header => !excludeHeaders.includes(header));

      data.forEach(row => {
        let tableRow: ITableRow = {
          tdColumn: filteredHeaders.map(header => {
            let value = row[header];
            let numericValue = parseFloat(value);
            if (header === ConnectionPerformance_AxialLoads_Column) {              
              if (!isNaN(Number(numericValue))) {
                value = numericValue.toFixed(2);
              }
              else {
                this.utilityService.defectiveVariableCP.push(header); 
                value = "";
              }
            }
            if (header === ConnectionPerformance_NetPrsr_Column) {              
              if (!isNaN(Number(numericValue))) {
                value = numericValue.toFixed(2);
              }
              else {
                this.utilityService.defectiveVariableCP.push(header); 
                value = "";
              }
            }
            if (header === ConnectionPerformance_PlotSeqnbr_Column) {             
              if (!isNaN(Number(numericValue))) {
                value = numericValue;
              }
              else {
                this.utilityService.defectiveVariableCP.push(header); 
                value = "";
              }
            }
            if (header === ConnectionPerformance_EnvlpType_Column) {
              return {
                editable: false,
                headerName: 'ENVLP_TYPE',
                value: value,
                errorMessage: '',
                headerPropertyName: header,
                isDropdown: true,
                options: this.procedureVTableDataForUOM,
                name: header
              }
            }
            return {
              editable: false,
              headerName: ConnectionPerformacneTable[header],
              value: value,
              errorMessage: '',
              headerPropertyName: header,
              name: header
            };
          }),
          editable: true,
          colspan: 1
        };

        this.connectionInformationColumnsData.push(tableRow);
      }); 
      this.saveCPdataLocalStorage();
    }));
  }

  private convertUOMAndReloadHeader() {
    const uom: string = this.unitOfMeasureService.getUnitOfMeasure();
    const data = this.utilityService.isCSEUpload === true ? this.utilityService.getConnectionPerformanceData(JSON.parse(sessionStorage.getItem(this.connectionPerformananceSpecificKey))) : this.utilityService.getConnectionPerformanceData(this.connectionInformationColumnsData);

    const connectionPerformances$ = this.utilityService.getConnectionPerformancesByUnitType({
      Unit: uom,
      ConnectionPerformances: data,
      ConnectionId: this.connectionId 
    });

    const headerUOM$ = this.loadCaseService.getAcronymForPressureTension();

    forkJoin([connectionPerformances$, headerUOM$]).subscribe(([convertedConnectionPerformancesData, responseData]) => {
      this.setConnectonPRMs(convertedConnectionPerformancesData, true);
      this.pressureUOM  = '';
      this.axialLoadTensionUOM  = '';
      this.pressureUOM = responseData.data.find(p => p.name.toLowerCase() === 'pressure').unitAcronym;
      this.axialLoadTensionUOM = responseData.data.find(p => p.name.toLowerCase() === 'tension').unitAcronym;
      this.connectionInformationHeader = this.getTableHeaderData(this.procedureVTableDataForUOM);
      this.cdr.detectChanges()
    });
  }

  public saveCPdataLocalStorage() {
    sessionStorage.setItem(this.connectionPerformananceSpecificKey, JSON.stringify(this.connectionInformationColumnsData));
  }
  public getCPdataLocalStorage() {
    let conPerArray = JSON.parse(sessionStorage.getItem(this.connectionPerformananceSpecificKey));
    return conPerArray;
  }
  deleteRow(index: number) {
    this.connectionInformationColumnsData.splice(index, 1);
  }

  clipboardCopied(clipboardCopiedData: string[]) {
    if (clipboardCopiedData && clipboardCopiedData.length > 0) {
      clipboardCopiedData.forEach(rowData => {
        if (rowData !== '') {
          let columnValues = rowData.split("\t");
          let tension: IColumn = {
            editable: false, headerName: ConnectionPerformance_EnvlpType, value: columnValues[0], errorMessage: '',
            headerPropertyName: ENVLP_TYPE_CD,
            options: this.procedureVTableDataForUOM
          };
          let specimen: IColumn = {
            editable: false, headerName: ConnectionPerformance_NetPrsr, value: columnValues[1], errorMessage: '',
            headerPropertyName: NET_PRSR
          };
          let axialLoad: IColumn = {
            editable: false, headerName: ConnectionPerformance_AxialLoads, value: columnValues[2], errorMessage: '',
            headerPropertyName: AXIAL_LOAD
          };
          let test: IColumn = {
            editable: false, headerName: ConnectionPerformance_PlotSeqnbr, value: columnValues[3], errorMessage: '',
            headerPropertyName: PLOT_SEQ_NBR
          };
          let date: IColumn = {
            editable: false, headerName: ConnectionPerformance_ModUid, value: columnValues[4], errorMessage: '',
            headerPropertyName: MOD_UID
          };
          let facility: IColumn = {
            editable: false, headerName: ConnectionPerformance_ChangeDate, value: columnValues[5], errorMessage: '',
            headerPropertyName: CHNG_DATE
          };
          let tableRow: ITableRow = { tdColumn: [tension, specimen, axialLoad, test, date, facility], editable: true, colspan: 1 };
          this.connectionInformationColumnsData.push(tableRow);
        }
      });
      this.handleNewEnvelopeTypeAddition();
      this.connectionInformationHeader = this.getTableHeaderData(this.procedureVTableDataForUOM);
      this.updateEvelopeTypeCD();
      this.utilityService.testInformationSectionRowUpdated.next(true);
    }
  }



  discard() {
    const connectionPerformananceData = JSON.parse(sessionStorage.getItem(this.connectionPerformananceSpecificKey));
    if (connectionPerformananceData != null && connectionPerformananceData.length > 0 && this.utilityService.isCSEUpload == true) {
      this.connectionInformationColumnsData = connectionPerformananceData;
    }
    if (connectionPerformananceData == null || this.utilityService.isCSEUpload == false) {
      this.connectionInformationColumnsData = [];
    }
  }

  @Output() NewEnvelopeTypeAdded = new EventEmitter<string[]>();


  setConnectonPRMs(connectionPerformances: IConnectionPerformance[], isfromUOM?: boolean): void {
    if (connectionPerformances && connectionPerformances.length > 0) {

      this.connectionInformationColumnsData = [];

      connectionPerformances?.forEach(connectionPerfomance => {
        let tension: IColumn = {
          editable: false, headerName: ConnectionPerformance_EnvlpType, value: connectionPerfomance.envolopeType, errorMessage: '',
          headerPropertyName: ENVLP_TYPE_CD,
          options: this.procedureVTableDataForUOM
        };
        let specimen: IColumn = {
          editable: false, headerName: ConnectionPerformance_NetPrsr + `(${this.pressureUOM})`, value: connectionPerfomance.netPrsr, errorMessage: '',
          headerPropertyName: NET_PRSR
        };
        let axialLoad: IColumn = {
          editable: false, headerName: ConnectionPerformance_AxialLoads + `(${this.axialLoadTensionUOM})`, value: connectionPerfomance.axialLoad, errorMessage: '',
          headerPropertyName: AXIAL_LOAD
        };
        let test: IColumn = {
          editable: false, headerName: ConnectionPerformance_PlotSeqnbr, value: connectionPerfomance.plotSeqNbr, errorMessage: '',
          headerPropertyName: PLOT_SEQ_NBR
        };
        let date: IColumn = {
          editable: false, headerName: ConnectionPerformance_ModUid, value: connectionPerfomance.modUid, errorMessage: '',
          headerPropertyName: MOD_UID
        };
        let facility: IColumn = {
          editable: false, headerName: ConnectionPerformance_ChangeDate, value: this.utilityService.formatDate(connectionPerfomance.changeDate), errorMessage: '',
          headerPropertyName: CHNG_DATE
        };
        let tableRow: ITableRow = { tdColumn: [tension, specimen, axialLoad, test, date, facility], editable: true, colspan: 1 };
        this.connectionInformationColumnsData.push(tableRow);
      });
      if (!isfromUOM) {
        this.saveCPdataLocalStorage();
      }
    }
  }

  updateEvelopeTypeCD(): void {
    this.connectionInformationColumnsData?.map(item => {
      item.tdColumn.forEach(col => {
        if (col.headerPropertyName === ENVLP_TYPE_CD) {
          col.options = this.procedureVTableDataForUOM;
        }
      });

    });
  }

  private handleNewEnvelopeTypeAddition() {

    if (this.procedureVTableDataForUOM && this.procedureVTableDataForUOM.length > 0) {
      const uniqueNewEnvelopes = this.utilityService.getConnectionPerformanceData(this.connectionInformationColumnsData)
        .filter(x => !this.procedureVTableDataForUOM.map(y => y.value).includes(x.ENVLP_TYPE_CD))
        .filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.ENVLP_TYPE_CD === value.ENVLP_TYPE_CD
          ))
        );
      if (uniqueNewEnvelopes && uniqueNewEnvelopes.length > 0) {
        const newMappedArray = uniqueNewEnvelopes.map(x => ({
          value: x.ENVLP_TYPE_CD,
          label: x.ENVLP_TYPE_CD
        }));

        if (this.procedureVTableDataForUOM && this.procedureVTableDataForUOM.length > 0) {
          this.procedureVTableDataForUOM = [
            ...this.procedureVTableDataForUOM,
            ...newMappedArray
          ];
        }
      }

      this.NewEnvelopeTypeAdded.emit(uniqueNewEnvelopes.map(x => x.ENVLP_TYPE_CD));
    }
  }
}

