import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  IUnitResult,
  IUnitResultReqeuest as IUnitResultRequest,
} from "../../../../../common/models/responses/interfaces/IUnitResult";
import { UnitOfMeasureModel } from '../../../../../common/models/unitOfMeasureModel';
import { TabStorageService } from "../../../../../service/tcdb/tabstorage.service";
import { UploadUtilityService } from "../../../pages/upload-utility/upload-utility.service";
import { IDetailSectionControl } from "../IDetailSectionControl";
import {
  CNCT_ANAL_DATE,
  MU_LOSS_LEN,
  CNCT_TNSN_RTNG,
  CNCT_CMPRS_RTNG,
  CNCT_INTRNL_PRSR_RTNG,
  CNCT_EXT_PRSR_RTNG,
  BOX_CPL_CRTC_XSA,
  PIN_CRTC_XSA,
  TEMP_RTNG,
  NOM_TNSL_EFC_PCT,
  NOM_CMPRS_EFC_PCT,
  NOM_INTRNL_PRSR_EFC_PCT,
  NOM_EXT_PRSR_EFC_PCT,
  RCMD_PIN_THRD_CMPND_WT,
  RCMD_BOX_CPL_THRD_CMPND_WT,
  RCMD_MIN_MU_TRQ,
  RCMD_MAX_MU_TRQ,
  RCDM_OPT_MU_TRQ,
  RCMD_MIN_SHLDR_TRQ,
  RCMD_MAX_SHLDR_TRQ,
  YLD_TRQ,
  MB_CYC_CNT,
  NOM_BOX_CPL_LNG,
  NOM_BOX_CPL_OD,
  PIPE_CRTC_XSA,
  PIPE_EXT_PRSR_RTNG,
  PIPE_INTRNL_PRSR_RTNG,
  PIPE_CMPRS_RTNG,
  PIPE_TENS_RTNG,
  NOM_PIPE_WALL_THCK,
  NOM_PIPE_OD_DIAM,
  ACTIVE_FLG,
  API_PRSR_RTNG_FLG,
  BOX_CPL_COAT_TYPE_NM,
  CNCT_GEOM_TYPE_NM,
  CNCT_GEOM_TYPE_VTBL,
  COAT_TYPE_VTBL,
  ConnectionDescription,
  DATA_SRC_NM,
  DATA_SRC_VTBL,
  DescriptonDetailControls,
  DropdownTypeAdditionalDetailSectionControlNames,
  DropDownYesNoOptions,
  DRVD_ENVLP_FLG,
  EXTDN_SEAL_TEST_FLG,
  FEA_FLG,
  FULL_CNCT_GRD_ID,
  FULLEVAL_FLG,
  INTRNL_QNCH_TEST_FLG,
  MANUF_NM,
  MANUF_VTBL,
  MULT_STEP_THRD_FLG,
  NOM_PIPE_WT,
  NOM_PIPE_YLD_STRGTH,
  PIN_COAT_TYPE_NM,
  PIPE_GRD_VTBL,
  PRDCTLN_FLG,
  SEAL_LOC_NM,
  SEAL_LOC_VTBL,
  SEAL_TEST_FLG,
  SEAL_TYPE_NM,
  SEAL_TYPE_VTBL,
  SHLDR_LOC_NM,
  SHLDR_LOC_VTBL,
  STRUCT_TEST_FLG,
  THRD_GEOM_TYPE_NM,
  THRD_GEOM_VTBL,
  THRD_TYPE_NM,
  THRD_TYPE_VTBL,
  THRM_CYC_TEST_FLG,
  VAR_LNG_BOX_CPL_FLG,
  THRD_CMPND_TYPE_VTBL,
  THRD_CMPND_APPL_TXT,
  variableSpecifications,
} from "../upload-utility-helper";
import { VTableService } from "../../../../../service/tcdb/vtable.service";
import { AlertErrorService } from "../../../../../service/tcdb/alert-error.service";
import { Constants } from "../../../../../common/constants/constant";
import { IConnectionDescCustomFields } from "app/common/models/requests/interfaces/ConnectionRequest";
import { UnitOfMeasureService } from "app/service/tcdb/unitofmeasure.service";
import { LoaderService } from "app/service/tcdb/loader.service";
import { ICandidateConnectionResult } from "app/common/models/responses/interfaces/iCandidateConnectionResult.interface";

@Component({
  selector: "app-details-section",
  templateUrl: "./details-section.component.html",
  styleUrls: ["./details-section.component.css"],
})
export class DetailsSectionComponent implements OnInit {
  public vTableDependents: { [tableName: string]: string[] } = {};
  connectionDescCustomFields: IConnectionDescCustomFields;
  detailsForm: FormGroup = new FormGroup({});
  @Input() isEdit: boolean;
  manufacturerlist: any[] = [];
  stewardingCompanyList: any[] = [];
  gradeList: any[];
  threadGeometryTypeList: any[];
  threadCompoundTypeList: any[];
  threadTypeList: any[];
  pinList: any[];
  couplingBoxList: any[];
  sealTypeList: any[];
  sealLocationList: any[];
  shoulderLocationList: any[];
  connectionTypeList: any[];
  threadCompoundCommentList: any[];
  dropDownOptions = [DropDownYesNoOptions.Yes, DropDownYesNoOptions.No];
  dropDownOptionsForNullables = ["", ...this.dropDownOptions];
  unitMeasures: IUnitResult = <any>{};
  connectionDescriptionEnums = ConnectionDescription;
  defectiveVariable: string[] = [];
  detailsSectionSpecificKey: string;
  // @Input() detailsData: any;
  // @Input() isDiscard: boolean = false;
  dropdownTypeAdditionalDetailSectionControlNames =
    DropdownTypeAdditionalDetailSectionControlNames;
   numVariables = [
     NOM_PIPE_OD_DIAM,
     NOM_PIPE_WT,
    NOM_PIPE_WALL_THCK,
    NOM_PIPE_YLD_STRGTH,
    PIPE_TENS_RTNG,
    PIPE_CMPRS_RTNG,
    PIPE_INTRNL_PRSR_RTNG,
    PIPE_EXT_PRSR_RTNG,
    PIPE_CRTC_XSA,
    NOM_BOX_CPL_OD,
    NOM_BOX_CPL_LNG,
    MU_LOSS_LEN,
    CNCT_TNSN_RTNG,
    CNCT_CMPRS_RTNG,
    CNCT_INTRNL_PRSR_RTNG,
    CNCT_EXT_PRSR_RTNG,
    BOX_CPL_CRTC_XSA,
    PIN_CRTC_XSA,
    TEMP_RTNG,
    NOM_TNSL_EFC_PCT,
    NOM_CMPRS_EFC_PCT,
    NOM_INTRNL_PRSR_EFC_PCT,
    NOM_EXT_PRSR_EFC_PCT,
    RCMD_PIN_THRD_CMPND_WT,
    RCMD_BOX_CPL_THRD_CMPND_WT,
    RCMD_MIN_MU_TRQ,
    RCMD_MAX_MU_TRQ,
    RCDM_OPT_MU_TRQ,
    RCMD_MIN_SHLDR_TRQ,
    RCMD_MAX_SHLDR_TRQ,
    YLD_TRQ,
    MB_CYC_CNT     
  ];
  dateVariables = [CNCT_ANAL_DATE];
  constructor(
    private uploadUtilityService: UploadUtilityService,
    private unitMeasure: UnitOfMeasureService,
    public tabStorageService: TabStorageService,
    public _vtableService: VTableService,
    private loaderService: LoaderService,
    private _alertErrorService: AlertErrorService
  ) {
    this.detailsSectionSpecificKey =
      "dsSpecificKeyToSave_" + this.tabStorageService.getTabId();
    this.getManufacturerList();
    this.getCouplingBox();
    this.getPin();
    this.getThreadType();
    this.getThreadGeometryType();
    this.getThreadCompoundType();
    this.getGrade();
    this.getStewardingCompany();
    this.getSealType();
    this.getSealLocation();
    this.getShoulderLocation();
    this.getConnectionType();
    this.uploadUtilityService.DetailsSectionData.subscribe((data) => {      
      const sealTypeData = data[1];

      const mapColumnName = (columnName) => {
        const columnMap = {
          RCDM_OPT_MU_TRQ: "RCMD_OPT_MU_TRQ"
        };
        return columnMap[columnName] || columnName;
      };

      data.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const mappedKey = mapColumnName(key);
          if (this.detailsForm.controls[mappedKey]) {
            let stringValue = String(value);

            // Check if the value is a date in the format "dd/MM/yyyy" or similar
            if (/\d{1,2}\/\d{1,2}\/\d{2,4}/.test(stringValue)) {
              const [day, month, year] = stringValue.split("/");
              const formattedDate = `${
                year.length === 2 ? "20" + year : year
              }-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
              this.isProgrammaticChange = true;
              this.detailsForm.controls[mappedKey].setValue(formattedDate);
              this.isProgrammaticChange = false;
            } else {
              // Handle numeric values
              if (
                !isNaN(Number(stringValue)) &&
                stringValue !== null &&
                stringValue !== ""
              ) {
                stringValue = parseFloat(stringValue).toFixed(2);
              }
              this.isProgrammaticChange = true;
              if (key == 'RCDM_OPT_MU_TRQ') { key = 'RCMD_OPT_MU_TRQ' }
              if (this.numVariables.includes(key) && (isNaN(Number(stringValue)) && stringValue != "" && stringValue != 'N/A') || (this.dateVariables.includes(key) && isNaN(Date.parse(stringValue)) && stringValue != "" && stringValue!='N/A')) {
                this.defectiveVariable.push(key);
              }
              else {
                this.detailsForm.controls[key].setValue(stringValue);
                this.isProgrammaticChange = false;
              }
            }
          }
        });
      });

      this.isProgrammaticChange = true;
      this.detailsForm.controls[
        this.connectionDescriptionEnums.e_SealShoulderInformation_ModifiedBy
      ].setValue(sealTypeData.MOD_UID);
      this.detailsForm.controls[
        this.connectionDescriptionEnums.e_SealShoulderInformation_ChangeDate
      ].setValue(sealTypeData.CHNG_DATE);
      this.isProgrammaticChange = false;
      this.saveDetailsSectionLocalStorage(this.detailsForm?.value);
      if (!this.isEdit) {
        this.getUOM();
      }
      if (this.uploadUtilityService.defectiveVariableCP.length > 0 || this.defectiveVariable.length > 0) {
        const detailSectionsErrorOuts = this.defectiveVariable.map(x => variableSpecifications[x]);
        const connectionPerformanceErrorOuts = this.uploadUtilityService.defectiveVariableCP.map(y => variableSpecifications[y]);
        const overAllErrorsOuts = connectionPerformanceErrorOuts.length == 0 ? detailSectionsErrorOuts : detailSectionsErrorOuts.length == 0 ? connectionPerformanceErrorOuts : detailSectionsErrorOuts + ',' + connectionPerformanceErrorOuts;
        if (overAllErrorsOuts.length > 0 && !this.uploadUtilityService.isConnectionEdit) {
          this._alertErrorService.errorMessage.next('Unable to load ' + overAllErrorsOuts + ' due to mismatch in data types.');
        }
      }
    });
  }

  setFormValues(apiResponse: ICandidateConnectionResult) {
    const patchValues = {
      // CNCT_ID: apiResponse.connectionId,
      Administrative_Comments: apiResponse.administrative_Comments,
      NOM_PIPE_OD_DIAM: apiResponse.od,
      NOM_PIPE_WT: apiResponse.weight,
      NOM_PIPE_WALL_THCK: apiResponse.wallThickness,
      NOM_PIPE_YLD_STRGTH: apiResponse.yield,
      PIPE_TENS_RTNG: apiResponse.tensionPipeBody,
      PIPE_CMPRS_RTNG: apiResponse.compressionPipebody,
      PIPE_INTRNL_PRSR_RTNG: apiResponse.internalPressurePipebody,
      PIPE_EXT_PRSR_RTNG: apiResponse.externalPressurePipebody,
      PIPE_CRTC_XSA: apiResponse.criticalCrossSectAreaPipeBody,
      //TODO: missing.
      CNCT_ANAL_DATE: this._vtableService.formatDate(
        apiResponse.connectionAnalysisDate
      ),
      //TODO
      DRVD_ENVLP_FLG: apiResponse.derivedEnvolope,
      MANUF_NM: apiResponse.manufacturer,
      CNCT_NM: apiResponse.connectionName,
      CNCT_GEOM_TYPE_NM: apiResponse.connectionTypeString,
      FULL_CNCT_GRD_ID: apiResponse.gradesReportString,
      NOM_BOX_CPL_OD: apiResponse.couplingBoxOD,
      //TODO
      TBG_QLF_FLG: apiResponse.tubingQualificationFlg,
      NOM_BOX_CPL_LNG: apiResponse.couplingBoxLength,
      //TODO:
      // VAR_LNG_BOX_CPL_FLG: apiResponse.variableLengthBox,
      MU_LOSS_LEN: apiResponse.makeupLoss,
      CNCT_TNSN_RTNG: apiResponse.tensionConnection,
      CNCT_CMPRS_RTNG: apiResponse.compressionConnection,
      CNCT_INTRNL_PRSR_RTNG: apiResponse.internalPressureConnection,
      CNCT_EXT_PRSR_RTNG: apiResponse.externalPressureConnection,
      BOX_CPL_CRTC_XSA: apiResponse.criticalCrossSectAreaCouplingBox,
      PIN_CRTC_XSA: apiResponse.criticalCrossSectAreaPin,
      NOM_TNSL_EFC_PCT: apiResponse.tensionEfficiency,
      NOM_CMPRS_EFC_PCT: apiResponse.compressionEfficiency,
      NOM_INTRNL_PRSR_EFC_PCT: apiResponse.internalPressureEfficiency,
      NOM_EXT_PRSR_EFC_PCT: apiResponse.externalPressureEfficiency,
      THRD_GEOM_TYPE_NM: apiResponse.threadGeometry,
      THRD_TYPE_NM: apiResponse.threadType,
      MULT_STEP_THRD_FLG: apiResponse.multiStepFlag,
      THRD_CMPND_APPL_TXT: apiResponse.threadCompoundComment,
      RCMD_PIN_THRD_CMPND_WT: apiResponse.threadCompoundPin,
      RCMD_BOX_CPL_THRD_CMPND_WT: apiResponse.threadCompoundBox,
      PIN_COAT_TYPE_NM: apiResponse.pinsurfacefinish,
      BOX_CPL_COAT_TYPE_NM: apiResponse.couplingsurfacefinish,
      RCMD_MIN_MU_TRQ:
        apiResponse.finalMin === "N/A" ? "" : apiResponse.finalMin,
      RCMD_MAX_MU_TRQ:
        apiResponse.finalMax === "N/A" ? "" : apiResponse.finalMax,
      RCMD_OPT_MU_TRQ:
        apiResponse.finalOptimum === "N/A" ? "" : apiResponse.finalOptimum,
      RCMD_MIN_SHLDR_TRQ:
        apiResponse.shoulderMin === "N/A" ? "" : apiResponse.shoulderMin,
      RCMD_MAX_SHLDR_TRQ:
        apiResponse.shoulderMax === "N/A" ? "" : apiResponse.shoulderMax,
      YLD_TRQ: apiResponse.yieldMin === "N/A" ? "" : apiResponse.yieldMin,
      BOX_CPL_DRW_NBR: apiResponse.box,
      PIN_DRW_NBR: apiResponse.pin,
      THRD_PROF_DRW_NBR: apiResponse.thread,
      DTL_DRW_NBR: apiResponse.details,
      TEST_SMPL_TXT: apiResponse.additionalQualificationComment,
      TEST_CMNT_TXT: apiResponse.testComments,
      MB_CYC_CNT: apiResponse.mbCycle,
      INTRNL_QNCH_TEST_FLG: apiResponse.internalQuenchTest,
      EXT_PRSR_TEST_FLG: apiResponse.externalQuenchTest,
      THRM_CYC_TEST_FLG: apiResponse.thermalCycleTest,
      STRUCT_TEST_FLG: apiResponse.structuralTest,
      RPT_ID: apiResponse.document,
      FEA_FLG: apiResponse.feaFlag,
      PRDCTLN_FLG: apiResponse.productionLineFlg,
      FULLEVAL_FLG: apiResponse.fullyEvaluationFlg,
      ACTIVE_FLG: apiResponse.activeFlag,

      FLD_TRQ: apiResponse.fieldTorque,
      DOC_LINK: apiResponse.documentLink,
      USE_DFLT_FLDR_FLG: apiResponse.useDefaultFolder,
      Fatigue_Evaluation: apiResponse.fatigue_Evaluation,
      Limited_Sealability_Envelope: apiResponse.limited_Sealability_Envelope,
      Bleed_Through: apiResponse.bleed_Through,
      Unconventional: apiResponse.unconventional,
      High_Torque: apiResponse.high_Torque,
      API_PRSR_RTNG_FLG: apiResponse.apiPressureRating,
      SEAL_TEST_FLG: apiResponse.sealTest,
      EXTDN_SEAL_TEST_FLG: apiResponse.externalSealTest,
      DATA_SRC_NM: apiResponse.stewardingCompany,
      CRA_FLG: apiResponse.craFlag,
      TEMP_RTNG: apiResponse.temperatureRating,
      SEAL_TYPE_NM: apiResponse.seaL_TYPE_NM,
      SEAL_LOC_NM: apiResponse.seaL_LOC_NM,
      SHLDR_LOC_NM: apiResponse.shldR_LOC_NM,
      SealShoulderInformation_ChangeDate:
        apiResponse.sealShoulderInformation_ChangeDate,
      SealShoulderInformation_ModifiedBy:
        apiResponse.sealShoulderInformation_ModifiedBy,
    };
    const formControls = this.detailsForm.controls;
    this.isProgrammaticChange = true;

    Object.keys(patchValues).forEach((key) => {
      if (formControls[key]) {
        formControls[key].setValue(patchValues[key]);
      }
    });
    this.isProgrammaticChange = false;

    this.detailsForm.markAsPristine();
    this.saveDetailsSectionLocalStorage(this.detailsForm?.value);
    //this.getUOM();
  }

  isProgrammaticChange = false;
  ngOnInit(): void {
    const constants: IDetailSectionControl[] = DescriptonDetailControls;

    constants.forEach((detailSectionControl: IDetailSectionControl) => {
      const validators = detailSectionControl.isRequired
        ? [Validators.required]
        : [];
      this.detailsForm.addControl(
        detailSectionControl.controlName,
        new FormControl(detailSectionControl.defaultValue, validators)
      );
    });
    this.unitMeasure.MetricDisabled.next(true);
    // if (!this.uploadUtilityService.isCSEUpload) {
    this.getUOMOnly();
    //}
    // if (this.uploadUtilityService.isCSEUpload) {
    //   this.getUOM();
    // }
    // this.unitMeasure.savedUnitOfMeasure.subscribe((changedUOM: UnitOfMeasureModel) => {
    //   if (changedUOM && changedUOM.unitOfMeasure !== undefined) {
    //     //this.getUOM();
    //   }
    // });
    this.detailsForm?.valueChanges.subscribe((x) => {
      if (!this.detailsForm?.pristine && !this.isProgrammaticChange) {
        this.uploadUtilityService.detailsFormDataDataChanged.next(true);
      }
    });
    this.uploadUtilityService.detailSectionLoaded.next(true);
  }

  public saveDetailsSectionLocalStorage(item: any) {
    sessionStorage.setItem(
      this.detailsSectionSpecificKey,
      JSON.stringify(item)
    );
  }
  public getDetailsSectionLocalStorage() {
    let detailsSectionArray = JSON.parse(
      sessionStorage.getItem(this.detailsSectionSpecificKey)
    );
    return detailsSectionArray;
  }
  getManufacturerList() {
    this.uploadUtilityService.getDistinctManufacturerList().subscribe((x) => {
      const existingManufacturers = x.data;
      const currentManufacturer = this.detailsForm.controls[MANUF_NM].value;
      if (
        !existingManufacturers.includes(currentManufacturer) &&
        currentManufacturer != "" &&
        currentManufacturer
      ) {
        existingManufacturers.push(currentManufacturer);
        this.vTableDependents[MANUF_VTBL] = [currentManufacturer];
        this.manufacturerlist = existingManufacturers;
      } else {
        this.manufacturerlist = existingManufacturers;
      }
    });
  }
  getStewardingCompany() {
    this.uploadUtilityService.getStewardingCompanyList().subscribe((x) => {
      const existingStewardingCompany = x.data;
      const currentStewardingCompany =
        this.detailsForm.controls[DATA_SRC_NM].value;
      if (
        !existingStewardingCompany.includes(currentStewardingCompany) &&
        currentStewardingCompany != "" &&
        currentStewardingCompany
      ) {
        existingStewardingCompany.push(currentStewardingCompany);
        this.vTableDependents[DATA_SRC_VTBL] = [currentStewardingCompany];
        this.stewardingCompanyList = existingStewardingCompany;
      } else {
        this.stewardingCompanyList = existingStewardingCompany;
      }
    });
  }
  getGrade() {
    this.uploadUtilityService.getGradeList().subscribe((x) => {
      const existingGradeList = x.data;
      const currentGrade = this.detailsForm.controls[FULL_CNCT_GRD_ID].value;
      if (
        !existingGradeList.includes(currentGrade) &&
        currentGrade != "" &&
        currentGrade
      ) {
        existingGradeList.push(currentGrade);
        this.vTableDependents[PIPE_GRD_VTBL] = [currentGrade];
        this.gradeList = existingGradeList;
      } else {
        this.gradeList = existingGradeList;
      }
    });
  }
  getThreadGeometryType() {
    this.uploadUtilityService.getThreadGeometryTypeList().subscribe((x) => {
      const existingThreadGeometryType = x.data;
      const currentThreadGeometryType =
        this.detailsForm.controls[THRD_GEOM_TYPE_NM].value;
      if (
        !existingThreadGeometryType.includes(currentThreadGeometryType) &&
        currentThreadGeometryType != "" &&
        currentThreadGeometryType
      ) {
        existingThreadGeometryType.push(currentThreadGeometryType);
        this.vTableDependents[THRD_GEOM_VTBL] = [currentThreadGeometryType];
        this.threadGeometryTypeList = existingThreadGeometryType;
      } else {
        this.threadGeometryTypeList = existingThreadGeometryType;
      }
    });
  }
  getThreadCompoundType() {
    this.uploadUtilityService.getThreadCompoundTypeList().subscribe((x) => {
      const existingThreadCompoundType = x.data;
      const currentThreadCompoundType =
        this.detailsForm.controls[THRD_CMPND_APPL_TXT].value;
      if (
        !existingThreadCompoundType.includes(currentThreadCompoundType) &&
        currentThreadCompoundType != "" &&
        currentThreadCompoundType
      ) {
        existingThreadCompoundType.push(currentThreadCompoundType);
        this.vTableDependents[THRD_CMPND_TYPE_VTBL] = [
          currentThreadCompoundType,
        ];
        this.threadCompoundTypeList = existingThreadCompoundType;
      } else {
        this.threadCompoundTypeList = existingThreadCompoundType;
      }
    });
  }
  getThreadType() {
    this.uploadUtilityService.getThreadTypeList().subscribe((x) => {
      const existingThreadType = x.data;
      const currentThreadType = this.detailsForm.controls[THRD_TYPE_NM].value;
      if (
        !existingThreadType.includes(currentThreadType) &&
        currentThreadType != "" &&
        currentThreadType
      ) {
        existingThreadType.push(currentThreadType);
        this.vTableDependents[THRD_TYPE_VTBL] = [currentThreadType];
        this.threadTypeList = existingThreadType;
      } else {
        this.threadTypeList = existingThreadType;
      }
    });
  }
  getPin() {
    this.uploadUtilityService.getPinList().subscribe((x) => {
      const existingPinList = x.data;
      const currentPin = this.detailsForm.controls[PIN_COAT_TYPE_NM].value;
      if (
        !existingPinList.includes(currentPin) &&
        currentPin != "" &&
        currentPin
      ) {
        existingPinList.push(currentPin);
        this.vTableDependents[COAT_TYPE_VTBL] = [currentPin];
        this.pinList = existingPinList;
      } else {
        this.pinList = existingPinList;
      }
    });
  }
  getCouplingBox() {
    this.uploadUtilityService.getCouplingBoxList().subscribe((x) => {
      const existingCouplingBoxList = x.data;
      const currentCouplingBox =
        this.detailsForm.controls[BOX_CPL_COAT_TYPE_NM].value;
      if (
        !existingCouplingBoxList.includes(currentCouplingBox) &&
        currentCouplingBox != "" &&
        currentCouplingBox
      ) {
        existingCouplingBoxList.push(currentCouplingBox);
        this.vTableDependents[COAT_TYPE_VTBL] = [currentCouplingBox];
        this.couplingBoxList = existingCouplingBoxList;
      } else {
        this.couplingBoxList = existingCouplingBoxList;
      }
    });
  }
  getSealType() {
    this.uploadUtilityService.getSealTypeList().subscribe((x) => {
      const existingSealTypeList = x.data;
      const currentSealType = this.detailsForm.controls[SEAL_TYPE_NM].value;
      if (
        !existingSealTypeList.includes(currentSealType) &&
        currentSealType != "" &&
        currentSealType
      ) {
        existingSealTypeList.push(currentSealType);
        this.vTableDependents[SEAL_TYPE_VTBL] = [currentSealType];
        this.sealTypeList = existingSealTypeList;
      } else {
        this.sealTypeList = existingSealTypeList;
      }
    });
  }
  getSealLocation() {
    this.uploadUtilityService.getSealLocationList().subscribe((x) => {
      const existingSealLocationList = x.data;
      const currentSealLocation = this.detailsForm.controls[SEAL_LOC_NM].value;
      if (
        !existingSealLocationList.includes(currentSealLocation) &&
        currentSealLocation != "" &&
        currentSealLocation
      ) {
        existingSealLocationList.push(currentSealLocation);
        this.vTableDependents[SEAL_LOC_VTBL] = [currentSealLocation];
        this.sealLocationList = existingSealLocationList;
      } else {
        this.sealLocationList = existingSealLocationList;
      }
    });
  }
  getShoulderLocation() {
    this.uploadUtilityService.getShoulderLocationList().subscribe((x) => {
      const existingShoulderLocationList = x.data;
      const currentShoulderLocation =
        this.detailsForm.controls[SHLDR_LOC_NM].value;
      if (
        !existingShoulderLocationList.includes(currentShoulderLocation) &&
        currentShoulderLocation != "" &&
        currentShoulderLocation
      ) {
        existingShoulderLocationList.push(currentShoulderLocation);
        this.vTableDependents[SHLDR_LOC_VTBL] = [currentShoulderLocation];
        this.shoulderLocationList = existingShoulderLocationList;
      } else {
        this.shoulderLocationList = existingShoulderLocationList;
      }
    });
  }
  getConnectionType() {
    this.uploadUtilityService.getConnectionTypeList().subscribe((x) => {
      const existingConnectionTypeList = x.data;
      const currentConnectionType =
        this.detailsForm.controls[CNCT_GEOM_TYPE_NM].value;
      if (
        !existingConnectionTypeList.includes(currentConnectionType) &&
        currentConnectionType != "" &&
        currentConnectionType
      ) {
        existingConnectionTypeList.push(currentConnectionType);
        this.vTableDependents[CNCT_GEOM_TYPE_VTBL] = [currentConnectionType];
        this.connectionTypeList = existingConnectionTypeList;
      } else {
        this.connectionTypeList = existingConnectionTypeList;
      }
    });
  }

  changedata(event: any) {
    console.log(event);
  }

  private getUOMOnly() {
    const inputData: IUnitResultRequest = {
      unit: Constants.unitUS,
      weight: 0,
      oD: 0,
      temperature: 0,
      wallThickness: 0,
      yield: 0,
      couplingBoxOD: 0,
      couplingBoxLength: 0,
      makeupLoss: 0,
      finalMin: 0,
      finalMax: 0,
      finalOptimum: 0,
      shoulderMin: 0,
      shoulderMax: 0,
      yieldMin: 0,
      tensionPipeBody: 0,
      tensionConnection: 0,
      tensionEfficiency: 0,
      compressionPipebody: 0,
      compressionConnection: 0,
      compressionEfficiency: 0,
      internalPressurePipebody: 0,
      internalPressureConnection: 0,
      internalPressureEfficiency: 0,
      externalPressurePipebody: 0,
      externalPressureConnection: 0,
      externalPressureEfficiency: 0,
      pipeCriticalCrossSection: 0,
      boxCriticalCrossSection: 0,
      pinCriticalCrossSection: 0,
    };
    const filteredInputData = Object.fromEntries(
      Object.entries(inputData).filter(([key, value]) => value !== "N/A")
    ) as IUnitResultRequest;
    this.uploadUtilityService
      .getUnitOfMeasureByUnitType(filteredInputData)
      .subscribe((data) => {
        this.unitMeasures = data;
      });
  }

  private getUOM() {
    let inputData: IUnitResultRequest;
    if (this.detailsForm) {
      const data = this.detailsForm.value;
      if (!data) {
        this.getUOMOnly();
      }
      inputData = {
        unit: Constants.unitUS,
        weight: data[this.connectionDescriptionEnums.e_NOM_PIPE_WT],
        oD: data[this.connectionDescriptionEnums.e_NOM_PIPE_OD_DIAM],
        temperature: data[this.connectionDescriptionEnums.e_TEMP_RTNG],
        wallThickness:
          data[this.connectionDescriptionEnums.e_NOM_PIPE_WALL_THCK],
        yield: data[this.connectionDescriptionEnums.e_NOM_PIPE_YLD_STRGTH],
        couplingBoxOD: data[this.connectionDescriptionEnums.e_NOM_BOX_CPL_OD],
        couplingBoxLength:
          data[this.connectionDescriptionEnums.e_NOM_BOX_CPL_LNG],
        makeupLoss: data[this.connectionDescriptionEnums.e_MU_LOSS_LEN],
        finalMin: data[this.connectionDescriptionEnums.e_RCMD_MIN_MU_TRQ],
        finalMax: data[this.connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ],
        finalOptimum: data[this.connectionDescriptionEnums.e_RCDM_OPT_MU_TRQ],
        shoulderMin: data[this.connectionDescriptionEnums.e_RCMD_MIN_SHLDR_TRQ],
        shoulderMax: data[this.connectionDescriptionEnums.e_RCMD_MAX_SHLDR_TRQ],
        yieldMin: data[this.connectionDescriptionEnums.e_YLD_TRQ],
        tensionPipeBody: data[this.connectionDescriptionEnums.e_PIPE_TENS_RTNG],
        tensionConnection:
          data[this.connectionDescriptionEnums.e_CNCT_TNSN_RTNG],
        tensionEfficiency:
          data[this.connectionDescriptionEnums.e_NOM_TNSL_EFC_PCT],
        compressionPipebody:
          data[this.connectionDescriptionEnums.e_PIPE_CMPRS_RTNG],
        compressionConnection:
          data[this.connectionDescriptionEnums.e_CNCT_CMPRS_RTNG],
        compressionEfficiency:
          data[this.connectionDescriptionEnums.e_NOM_CMPRS_EFC_PCT],
        internalPressurePipebody:
          data[this.connectionDescriptionEnums.e_PIPE_INTRNL_PRSR_RTNG],
        internalPressureConnection:
          data[this.connectionDescriptionEnums.e_CNCT_INTRNL_PRSR_RTNG],
        internalPressureEfficiency:
          data[this.connectionDescriptionEnums.e_NOM_INTRNL_PRSR_EFC_PCT],
        externalPressurePipebody:
          data[this.connectionDescriptionEnums.e_PIPE_EXT_PRSR_RTNG],
        externalPressureConnection:
          data[this.connectionDescriptionEnums.e_CNCT_EXT_PRSR_RTNG],
        externalPressureEfficiency:
          data[this.connectionDescriptionEnums.e_NOM_EXT_PRSR_EFC_PCT],
        pipeCriticalCrossSection:
          data[this.connectionDescriptionEnums.e_PIPE_CRTC_XSA],
        boxCriticalCrossSection:
          data[this.connectionDescriptionEnums.e_BOX_CPL_CRTC_XSA],
        pinCriticalCrossSection:
          data[this.connectionDescriptionEnums.e_PIN_CRTC_XSA],
      };
    }

    const filteredInputData = Object.fromEntries(
      Object.entries(inputData).filter(([key, value]) => value !== "N/A")
    ) as IUnitResultRequest;

    this.uploadUtilityService
      .getUnitOfMeasureByUnitType(filteredInputData)
      .subscribe((data) => {
        this.unitMeasures = data;
        this.loaderService.isLoading.next(true);
        const formControls = this.detailsForm.controls;

        const patchValues = {
          [this.connectionDescriptionEnums.e_NOM_PIPE_WT]: data.weight,
          [this.connectionDescriptionEnums.e_NOM_PIPE_OD_DIAM]: data.od,
          [this.connectionDescriptionEnums.e_NOM_PIPE_WALL_THCK]:
            data.wallThickness,
          [this.connectionDescriptionEnums.e_NOM_PIPE_YLD_STRGTH]: data.yield,
          [this.connectionDescriptionEnums.e_NOM_BOX_CPL_OD]:
            data.couplingBoxOD,
          [this.connectionDescriptionEnums.e_NOM_BOX_CPL_LNG]:
            data.couplingBoxLength,
          [this.connectionDescriptionEnums.e_MU_LOSS_LEN]: data.makeupLoss,
          [this.connectionDescriptionEnums.e_RCMD_MIN_MU_TRQ]: data.finalMin,
          [this.connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ]: data.finalMax, //RCMD_MAX_MU_TRQ
          [this.connectionDescriptionEnums.e_RCDM_OPT_MU_TRQ]:
            data.finalOptimum,
          [this.connectionDescriptionEnums.e_RCMD_MIN_SHLDR_TRQ]:
            data.shoulderMin,
          [this.connectionDescriptionEnums.e_RCMD_MAX_SHLDR_TRQ]:
            data.shoulderMax,
          [this.connectionDescriptionEnums.e_YLD_TRQ]: data.yieldMin,
          [this.connectionDescriptionEnums.e_PIPE_TENS_RTNG]:
            data.tensionPipeBody,
          [this.connectionDescriptionEnums.e_CNCT_TNSN_RTNG]:
            data.tensionConnection,
          //[this.connectionDescriptionEnums.e_NOM_TNSL_EFC_PCT]: data.tensionEfficiency,
          [this.connectionDescriptionEnums.e_PIPE_CMPRS_RTNG]:
            data.compressionPipebody,
          [this.connectionDescriptionEnums.e_CNCT_CMPRS_RTNG]:
            data.compressionConnection,
          // [this.connectionDescriptionEnums.e_NOM_CMPRS_EFC_PCT]: data.compressionEfficiency,
          [this.connectionDescriptionEnums.e_PIPE_INTRNL_PRSR_RTNG]:
            data.internalPressurePipebody,
          [this.connectionDescriptionEnums.e_CNCT_INTRNL_PRSR_RTNG]:
            data.internalPressureConnection,
          // [this.connectionDescriptionEnums.e_NOM_INTRNL_PRSR_EFC_PCT]: data.internalPressureEfficiency,
          [this.connectionDescriptionEnums.e_PIPE_EXT_PRSR_RTNG]:
            data.externalPressurePipebody,
          [this.connectionDescriptionEnums.e_CNCT_EXT_PRSR_RTNG]:
            data.externalPressureConnection,
          [this.connectionDescriptionEnums.e_PIPE_CRTC_XSA]:
            data.pipeCriticalCrossSection,
          [this.connectionDescriptionEnums.e_BOX_CPL_CRTC_XSA]:
            data.boxCriticalCrossSection,
          [this.connectionDescriptionEnums.e_PIN_CRTC_XSA]:
            data.pinCriticalCrossSection,
          // [this.connectionDescriptionEnums.e_NOM_EXT_PRSR_EFC_PCT]: data.externalPressureEfficiency,
        };
        this.isProgrammaticChange = true;

        Object.keys(patchValues).forEach((key) => {
          if (formControls[key]) {
            formControls[key].setValue(patchValues[key]);
          }
        });
        this.isProgrammaticChange = false;

        this.detailsForm.markAsPristine();
        this.loaderService.isLoading.next(false);
      });
  }

  get getConnectionDetailFormData() {
    const formData = { ...this.detailsForm.value };

    const controlsToReplaceNull = [
      ACTIVE_FLG,
      VAR_LNG_BOX_CPL_FLG,
      MULT_STEP_THRD_FLG,
      DRVD_ENVLP_FLG,
      API_PRSR_RTNG_FLG,
      SEAL_TEST_FLG,
      EXTDN_SEAL_TEST_FLG,
      THRM_CYC_TEST_FLG,
      STRUCT_TEST_FLG,
      PRDCTLN_FLG,
      FEA_FLG,
      FULLEVAL_FLG,
      NOM_PIPE_YLD_STRGTH,
      NOM_PIPE_WT,
      INTRNL_QNCH_TEST_FLG,
      THRD_GEOM_TYPE_NM,
      THRD_TYPE_NM,
      PIN_COAT_TYPE_NM,
      BOX_CPL_COAT_TYPE_NM,
    ];

    controlsToReplaceNull.forEach((control) => {
      if (formData[control] === "") {
        formData[control] = null;
      }
    });

    return formData;
  }

  updateCustomLogicFields(
    connectionDescCustomFields: IConnectionDescCustomFields
  ): void {
    this.connectionDescCustomFields = connectionDescCustomFields;
  }
}
