import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { IConnectionPerformanceDTO, IConnectionRequest } from '../../../../common/models/requests/interfaces/ConnectionRequest';
import { ICandidateConnectionResult, IConnectionPerformance } from '../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';
import * as XLSX from 'xlsx';
import { AXIAL_LOAD, CHNG_DATE, CSE_FILE_SHEET_NAME, MOD_UID, NET_PRSR } from '../../components/upload-utility/upload-utility-helper';

import { Router } from '@angular/router';
import { IFilterResponse } from '../../../../common/models/responses/interfaces/iFilterResponse.interface';
import { LogoPath } from '../../../../common/Enums/logoPath';
import { EndPoints } from '../../../../service/app-config/endpoints';
import { ITableRow } from 'app/common/models/components/iTableRow';
import { IUnitResultReqeuest, IUnitResult, IGetConnectionPerformancesByUnitType } from 'app/common/models/responses/interfaces/IUnitResult';
import { IVTableResponse } from 'app/common/models/responses/interfaces/IVTableResponse';

@Injectable({
  providedIn: 'root'
})
export class UploadUtilityService {
  private endpoint: string;
  private connectionPerformanceUOMEndPoint: string;
  private saveConnectionEndPoint: string;
  private deleteConnectionEndPoint: string;
  public DetailsSectionData: Subject<any> = new Subject<any>();
  public connectionPerformanceSectionData: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private detailsSectionExtractedData: any;
  private connectionPerformanceSectionExtractedData: any;
  public isCSEUpload: boolean;
  public isManualEntry: boolean;
  public errorMessage: string = '';
  public tabs: string = '';
  public isValidationDone: Subject<boolean> = new Subject<boolean>();
  public connectionPerformanceLoaded = new Subject<boolean>();
  public detailSectionLoaded = new Subject<boolean>();
  public connectionPerformanceSectionRowUpdated: Subject<boolean> = new Subject<boolean>();
  public testInformationSectionRowUpdated: Subject<boolean> = new Subject<boolean>();
  public isTableColumnsEditable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showCopyExcel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showAddButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public detailsFormDataDataChanged: Subject<boolean> = new Subject<boolean>();
  public searchResultConnectionDeleted: Subject<number> = new Subject<number>();
  private getVTableConnectionEndPoint: string;
  public connectionPeformanceDropDownData: Subject<number> = new Subject<number>();
  public testInformationDropDownData: Subject<number> = new Subject<number>();
  defectiveVariableCP: string[] = [];
  isConnectionEdit: boolean;


  constructor(private router: Router, private http: HttpClient) {
    this.endpoint = `${EndPoints.API_BASE_URL}/InputData/GetUnitOfMeasureByUnitType/`;
    this.detailSectionLoaded.subscribe(x => {
      if (this.isCSEUpload) {
        this.DetailsSectionData.next(this.detailsSectionExtractedData);
      }
    });
    this.connectionPerformanceLoaded.subscribe(x => {
      if (this.isCSEUpload) {
        this.connectionPerformanceSectionData.next(this.connectionPerformanceSectionExtractedData);
      }
    });
    this.saveConnectionEndPoint = `${EndPoints.API_BASE_URL}/InputData/SaveConnection`;
    this.deleteConnectionEndPoint = `${EndPoints.API_BASE_URL}/InputData/DeleteConnection`;
    this.connectionPerformanceUOMEndPoint = `${EndPoints.API_BASE_URL}/InputData/GetConnectionPerformancesByUnitType`;
    this.getVTableConnectionEndPoint = `${EndPoints.API_BASE_URL}/InputData/GetVTableDataByTableNames`;
  }

  getUnitOfMeasureByUnitType(unitResultReqeuest: IUnitResultReqeuest): Observable<IUnitResult> {
    return this.http.post<IUnitResult>(this.endpoint, unitResultReqeuest).pipe(
      map((result: any) => result.data)
    );
  }

  getVTableDataByTableNames(tableNames: string[]): Observable<IVTableResponse> {
    return this.http.post<IVTableResponse>(this.getVTableConnectionEndPoint, tableNames).pipe(
      map((result: any) => result.data)
    );
  }

  getDistinctManufacturerList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetManufacturerList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }

  getStewardingCompanyList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetStewardingCompanyList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getGradeList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetGradeList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getThreadGeometryTypeList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetThreadGeometryTypeList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getThreadCompoundTypeList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetThreadCompoundTypeList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getThreadTypeList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetThreadTypeList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getPinList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetPinList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getCouplingBoxList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetCouplingBoxList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getSealTypeList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetSealTypeList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getSealLocationList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetSealLocationList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getShoulderLocationList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetShoulderLocationList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getConnectionTypeList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetConnectionTypeList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }
  getThreadCompoundCommentList(): Observable<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/InputData/GetThreadCompoundCommentList`;
    return this.http
      .get<IFilterResponse>(endpoint);
  }


  getConnectionPerformancesByUnitType(unitResultReqeuest: IGetConnectionPerformancesByUnitType): Observable<IConnectionPerformance[]> {
    return this.http.post<IConnectionPerformance[]>(this.connectionPerformanceUOMEndPoint, unitResultReqeuest).pipe(
      map((result: any) => result.data)
    );
  }

  saveConnection(saveConnectionData: IConnectionRequest): Observable<any> {
    return this.http.post<ICandidateConnectionResult[]>(this.saveConnectionEndPoint, saveConnectionData).pipe(
      map((result: any) => result.data)
    );
  }

  deleteConnection(connectionId: number): Observable<any> {
    return this.http.post<any>(this.deleteConnectionEndPoint, { ConnectionId: connectionId }).pipe(
      map((result: any) => result.data)
    );
  }

  public mapToDropdownOptions(data, valueColumn, labelColumn) {
    return data.map(item => ({
      value: item[valueColumn],
      label: item[labelColumn]
    }));
  }



  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    // if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onloadend = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      this.errorMessage = undefined;
      this.tabs = '';

      wb.SheetNames.forEach((sheetName: string) => {

        switch (sheetName) {
          case CSE_FILE_SHEET_NAME.CNCT_DESC:
            const validData = this.processMultipleSheetsData(wb, [CSE_FILE_SHEET_NAME.CNCT_DESC]);
            if (validData.length > 1) {
              const detailsData = this.processMultipleSheetsData(wb, [CSE_FILE_SHEET_NAME.CNCT_DESC, CSE_FILE_SHEET_NAME.CNCT_SEAL_TYPE, CSE_FILE_SHEET_NAME.CNCT_SEAL_LOC, CSE_FILE_SHEET_NAME.CNCT_SHLDR_LOC, CSE_FILE_SHEET_NAME.CNCT_PRFM]);
              this.publishDetailSectionBindingInfo(detailsData);
            } else {
              this.showError(CSE_FILE_SHEET_NAME.CNCT_DESC);
            }
            break;
          case CSE_FILE_SHEET_NAME.CNCT_PRFM:
            const connectionPerformanceData = this.processMultipleSheetsData(wb, [CSE_FILE_SHEET_NAME.CNCT_PRFM]);
            if (connectionPerformanceData.length > 1) {
              this.publishConnectionInfoBindingInfo(connectionPerformanceData);
            } else {
              this.showError(CSE_FILE_SHEET_NAME.CNCT_PRFM);
            }
            break;
          case CSE_FILE_SHEET_NAME.CNCT_SEAL_TYPE:
            const connectionSealTypeData = this.processMultipleSheetsData(wb, [CSE_FILE_SHEET_NAME.CNCT_SEAL_TYPE]);
            if (connectionSealTypeData.length > 1) {
              //this.publishConnectionInfoBindingInfo(connectionSealTypeData);
            } else {
              this.showError(CSE_FILE_SHEET_NAME.CNCT_SEAL_TYPE);
            }
            break;
          case CSE_FILE_SHEET_NAME.CNCT_SEAL_LOC:
            const connectionSealLocData = this.processMultipleSheetsData(wb, [CSE_FILE_SHEET_NAME.CNCT_SEAL_LOC]);
            if (connectionSealLocData.length > 1) {
              //this.publishConnectionInfoBindingInfo(connectionSealLocData);
            } else {
              this.showError(CSE_FILE_SHEET_NAME.CNCT_SEAL_LOC);
            }
            break;
          case CSE_FILE_SHEET_NAME.CNCT_SHLDR_LOC:
            const connectionShldrData = this.processMultipleSheetsData(wb, [CSE_FILE_SHEET_NAME.CNCT_SHLDR_LOC]);
            if (connectionShldrData.length > 1) {
              //this.publishConnectionInfoBindingInfo(connectionShldrData);
            } else {
              this.showError(CSE_FILE_SHEET_NAME.CNCT_SHLDR_LOC);
            }
            break;
          default:
            break;
        }
      });
      if (this.errorMessage === undefined) {
        this.router.navigate(['/upload-utility', true]);
      }
      else {
        this.isValidationDone.next(false);
      }
    }

    reader.readAsBinaryString(target.files[0]);
  }

  private showError(tabName: string) {
    if (this.errorMessage === undefined || this.errorMessage.indexOf('Parent columns') == -1) {
      if (this.tabs == '') {
        this.tabs = tabName;
        this.errorMessage = "There is no data in " + this.tabs + " sheet.";
      } else {
        this.tabs = this.tabs + ", " + tabName;
        this.errorMessage = "There is no data in " + this.tabs + " sheet.";
      }
    }
  }

  private processMultipleSheetsData(wb: XLSX.WorkBook, sheetNames: string[]): string[][] {
    let allData: string[][] = [];

    sheetNames.forEach(sheetName => {
      const ws: XLSX.WorkSheet = wb.Sheets[sheetName];
      const csvData = XLSX.utils.sheet_to_csv(ws); // Convert sheet to CSV format

      // Manually parse CSV data
      const rows = csvData.split('\n').map(row => {
        const cells = [];
        let cell = '';
        let inQuotes = false;

        for (let char of row) {
          if (char === '"' && inQuotes) {
            inQuotes = false;
          } else if (char === '"') {
            inQuotes = true;
          } else if (char === ',' && !inQuotes) {
            cells.push(cell.trim());
            cell = '';
          } else {
            cell += char;
          }
        }
        cells.push(cell.trim());
        return cells;
      });

      const jsonData = rows
        .filter(row => row.some(cell => cell !== ''))
        .map(row => row.map(cell => {
          if (cell.includes('%')) {
            return cell.replace('%', '').trim();
          } else if (/^\d{1,3}(,\d{3})*(\.\d+)?$/.test(cell)) {
            return cell.replace(/,/g, ''); // Remove commas from numeric values
          } else {
            return cell.trim();
          }
        }));

      allData = allData.concat(jsonData);
    });

    return allData;
  }

  getManufacturerLogo(manufacturer: string): string {
    manufacturer = manufacturer.trim().toLowerCase();
    switch (manufacturer) {
      case manufacturer = 'antares':
        return LogoPath.Antares;
      case manufacturer = 'atlas bradford':
        return LogoPath.AtlasBradford;
      case manufacturer = 'benoit':
        return LogoPath.Benoit;
      case manufacturer = 'ge':
        return LogoPath.GE;
      case manufacturer = 'hunting':
        return LogoPath.Hunting;
      case manufacturer = 'jfe':
        return LogoPath.JFE;
      case manufacturer = 'mannesmann':
        return LogoPath.Mannesmann;
      case manufacturer = 'nippon steel':
        return LogoPath.NipponSteel;
      case manufacturer = 'nkk':
        return LogoPath.NKK;
      case manufacturer = 'nov':
        return LogoPath.NOV;
      case manufacturer = 'osi':
        return LogoPath.OSI;
      case manufacturer = 'precision':
        return LogoPath.Precision;
      case manufacturer = 'sumitomo':
        return LogoPath.Sumitomo;
      case manufacturer = 'tenarishydril':
        return LogoPath.TenarisHydril;
      case manufacturer = 'tmk':
        return LogoPath.TMK;
      case manufacturer = 'tpco':
        return LogoPath.TPCO;
      case manufacturer = 'tubacex':
        return LogoPath.Tubacex;
      case manufacturer = 'vam':
        return LogoPath.VAM;
      default:
        return LogoPath.Default;
    }
  }




  private publishDetailSectionBindingInfo(data: any[]) {
    const TotalNumberofColsinCNCT_DESCSheet = 69;
    this.detailsSectionExtractedData = this.extractExcelData(data);
    const mainConnID = this.detailsSectionExtractedData[0].CNCT_ID;
    if (this.detailsSectionExtractedData.filter(x => x.CNCT_ID === mainConnID).length != 5) {
      this.errorMessage = "Parent columns and child columns don't have type matching columns(CNCT_ID)"
    }

    if (Object.keys(this.detailsSectionExtractedData[0]).filter(x => x != '').length != TotalNumberofColsinCNCT_DESCSheet) {
      this.errorMessage = "Some columns are missing in the CNCT_DESC sheet."
    }
  }

  private extractExcelData(data: any[]) {
    if (data && data.length > 1) {
      const result: any[] = [];

      for (let i = 0; i < data.length; i += 2) {
        const columnsHeader: any = data[i];
        const rowsData: any = data[i + 1];

        if (columnsHeader && rowsData) {
          const rowObject = columnsHeader.reduce((acc, key, index) => {
            acc[key] = rowsData[index];
            return acc;
          }, {});
          result.push(rowObject);
        }
      }

      return result;
    }
    return [];
  }


  private publishConnectionInfoBindingInfo(data: any[]) {
    this.connectionPerformanceSectionExtractedData = this.extractConnectionInfoExcelData(data);
  }

  private extractConnectionInfoExcelData(data: any[]) {
    if (data && data.length > 1) {

      const columnsHeader: any = data[0];
      const rowsData: any[] = data.filter(x => x.length > 0).slice(1);

      return rowsData.map(row => {
        return columnsHeader.reduce((acc, key, index) => {
          acc[key] = row[index];
          return acc;
        }, {});
      });
    };
  }

  getConnectionPerformanceData(connectionInformationColumnsData: ITableRow[]): IConnectionPerformanceDTO[] {
    return connectionInformationColumnsData?.map(item => {
      let obj = {};
      item.tdColumn.forEach(col => {
        if ((col.headerPropertyName === CHNG_DATE || col.headerPropertyName === MOD_UID) && item.isEdited) {
          col.value = '';
        }
        if (col.headerPropertyName === CHNG_DATE) {
          if (!col.value || col.value === '' || col.value === '\r') {
            obj[col.headerPropertyName] = new Date().toISOString().slice(0, 10);
          } else {
            obj[col.headerPropertyName] = this.formatDate(col.value);
          }
        } else if (col.headerPropertyName === NET_PRSR || col.headerPropertyName === AXIAL_LOAD) {
          if (col.value === '' || col.value === 'NaN') {
            obj[col.headerPropertyName] = null;
          } else {
            obj[col.headerPropertyName] = parseFloat(col.value);
          }
        } else {
          obj[col.headerPropertyName] = col.value;
        }      
      });
      return obj;
    }) as IConnectionPerformanceDTO[];
  }

  public formatDate(dateString: string): string {
    if (!dateString || dateString === '' || dateString === '\r') {
      return '';
    }
    const d = new Date(dateString);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


}
