import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/icandidateConnectionResult.interface';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IExpansionPanelModel, IExpansionPanelItem, IExpansionPanelChildItem } from '../../../../common/models/components/expansionpanelmodel.interface';
import { ISearchCriteriaRequest } from '../../../../common/models/requests/interfaces/isearchcriteriarequest';
import { SearchCriteriaRequest } from '../../../../common/models/requests/searchcriteriarequest';
import { SearchCriteriaService } from '../../../../service/tcdb/searchcriteria.service';
import { Constants } from '../../../../common/constants/constant';
import { ConnectionUses } from '../../../../common/Enums/ConnectionUses'
import { InputDataService } from '../../../../service/tcdb/inputdata.service';
import { MeasureService } from '../../../../service/tcdb/measure.service';
import { OuterDiameterWallThicknessAndWeight } from '../../../../common/models/requests/outerDiameterWallThicknessAndWeight';
import { IManufacturerFilterResponseItem } from '../../../../common/models/responses/interfaces/iManufacturerFilterResponse';
import { Evaluation } from '../../../../common/Enums/Evaluation';
import { LogoPath } from '../../../../common/Enums/logopath';
import { ISortByResponse } from '../../../../common/models/responses/interfaces/iSortByResponse';
import { SortBy } from '../../../../common/Enums/sortBy';
import { IFilterChipItem } from '../../../../common/models/components/iFilterChipItem';
import { isNumeric, isValidTextWithSomeSpecialCharacterRestrictions, removeNotAllowedSpecialCharacters, sleep } from '../../../../common/utilities/utilities';
import { UnitOfMeasureService } from '../../../../service/tcdb/unitofmeasure.service';
import { CandidateViewDetailsComponent } from '../candidate-view-details/candidate-view-details.component';

declare var require: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { UnitOfMeasure } from '../../../../common/Enums/unitOfMeasure';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';
import { MsalService } from '@azure/msal-angular';
import { Active } from '../../../../common/Enums/active';
import { TabStorageService } from '../../../../service/tcdb/tabstorage.service';
import { AlertErrorService } from '../../../../service/tcdb/alert-error.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TedIntegrationService } from '../../../../service/tcdb/tedintegration.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadCaseService } from '../../../../service/tcdb/loadcaseservice';
import { SealabilityTypes } from '../../../../common/enums/sealabilitytypes';
import { MainHeaderService } from '../../../../service/tcdb/mainheader.service';
import { section } from '../../../../common/models/section.model';
import { welldesc } from '../../../../common/models/welldesc.model';
import { DatePipe } from '@angular/common';
import { UploadUtilityComponent } from '../upload-utility/upload-utility/upload-utility.component';
import { UploadUtilityService } from '../upload-utility/upload-utility.service';
import { Share2DrillPlanMessages, Share2DrillPlanDisclaimerForSearchCase, Share2DrillPlanDisclaimerForResetCase } from '../../components/upload-utility/upload-utility-helper';
import { pressureAndTension } from 'app/common/models/load.model';
import { IOuterDiameterWallThicknessAndWeight } from 'app/common/models/requests/interfaces/iOuterDiameterWallThicknessAndWeight';
import { IDeleteConnection } from 'app/common/models/responses/interfaces/IDeleteConnection.interface';
const htmlToPdfmake = require("html-to-pdfmake");
// In the module where pdfMake is defined

export let vfs = {};

export function setVfs(newVfs) {
  vfs = newVfs;
}

if (pdfFonts && pdfFonts.pdfMake && pdfFonts.pdfMake.vfs) {
  setVfs(pdfFonts.pdfMake.vfs);
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [InputDataService, SearchCriteriaService]
})
export class SearchComponent implements OnInit, OnDestroy {
  public useCheckbox = false;
  public expansionFilters: IExpansionPanelModel[] = [];
  public searchCriteriaRequest: ISearchCriteriaRequest = new SearchCriteriaRequest();
  public searchCriteriaRequestTitleResult: ISearchCriteriaRequest = new SearchCriteriaRequest();
  public candidateConnectionResults: ICandidateConnectionResult[] = [];
  public candidateConnectionResultsWithoutSort: ICandidateConnectionResult[] = [];
  public candidateConnectionResultsWithEnvelope: ICandidateConnectionResult[] = [];
  public candidateConnectionResultsPagination: ICandidateConnectionResult[] = [];
  public sortByResponse: ISortByResponse = { data: [], message: '' };
  public filterChipItems: IFilterChipItem[] = [];
  public filterChipItemsToPDF = '';
  public offset: number = 0;
  public viewCount: number = 10;
  public numberOfPages: number = 0;
  public pageSelected: number = 1;
  public isToShowViewDetails = false;
  public addConnection = false;
  public candidateConnectionResultViewDetails: ICandidateConnectionResult = new CandidateConnectionResult();
  public expansionPanelLoaded = new BehaviorSubject(false);
  userRoles: string[];
  adminUser: boolean = false;
  activeList = [Active.Yes, Active.No];
  viewDetailsConnectionId = '';
  @ViewChild(CandidateViewDetailsComponent) public candidateViewDetailsComponent: CandidateViewDetailsComponent;
  searchEmptyTitle: string = Constants.LandingMessageTitle;
  searchEmptyMessage: string = Constants.LandingMessage;
  paddingVal = "search-heading";
  tabId: string;
  expFilters: IExpansionPanelModel[] = [];
  cesReportId = "";
  _runId = "";
  successMessage: string;
  subscriptionList: Subscription[] = [];
  initialCriteriaFromDP: ISearchCriteriaRequest;
  isFromInitialSearchForDP: boolean;


  constructor(public _inputDataService: InputDataService, private _searchCriteriaService: SearchCriteriaService,
    private _unitOfMeasureService: UnitOfMeasureService, private _msalService: MsalService, private _tabStorageService: TabStorageService,
    private _measureService: MeasureService, private _alertErrorService: AlertErrorService, private _route: ActivatedRoute,
    private _tedIntegrationService: TedIntegrationService, private _loadCaseService: LoadCaseService, private _mainHeaderService: MainHeaderService,
    private datePipe: DatePipe, private uploadUtilityService: UploadUtilityService, private router: Router) {
    localStorage.setItem('MenuSelected', 'search');
    this.tabId = 'SearchFiltersToSave_' + this._tabStorageService.getTabId();
    this.addConnection = ((localStorage.getItem('AddNewConnection') == null || localStorage.getItem('AddNewConnection') == '') ? false : true);
    if (this.addConnection == true) {
      this.resetFilters();
    }
    _route.queryParams.subscribe(async (params) => {
      let runid = params['runid'];
      this._runId = runid;
      if(!this._loadCaseService.isFromDrillPlan){
        const isFromDrillPlan = Boolean(params['isFromDrillPlan']);
        this._loadCaseService.saveDrillPlanFlag(isFromDrillPlan);
        this.isFromInitialSearchForDP = true;
      }
      if (runid) {
        let tedIntegrationResponse = await _tedIntegrationService.getTedIntegrationInfo(runid);
        this.expansionPanelLoaded.subscribe(async isLoaded => {
          if (isLoaded && tedIntegrationResponse && this.expansionFilters.length > 0) {
            this.selectExpansionPanelFiltersBasedOnSearchCriteria(tedIntegrationResponse.searchCriteria);
            this._unitOfMeasureService.setUnitOfMeasure(tedIntegrationResponse.uom, false);
            this.prepareAndSaveLoadsFromTedIntegration(tedIntegrationResponse.section, tedIntegrationResponse.well);
          }
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptionList.forEach(x => x.unsubscribe());
  }

  async ngOnInit() {
    this.userRoles = this._msalService.instance.getAllAccounts()[0].idTokenClaims.roles;
    this.adminUser = this.userRoles.includes('TCDBAdministrator');
    this._tabStorageService.adminUser = this.adminUser;
    this._unitOfMeasureService.savedUnitOfMeasure.subscribe(async (unitOfMeasure) => {
      if (unitOfMeasure && unitOfMeasure.reloadFilters) {
        await this.reloadFiltersAffectedByUOM();
        if (this.searchCriteriaRequest && !this._searchCriteriaService.checkValidity(this.searchCriteriaRequest)) {
          await this.onSearch();
        }
      }
    });
    //check if filters exist in the session storage and re-apply
    if (this._searchCriteriaService.checkFiltersAvailable() && !this._runId) {
      this.expansionFilters = await this._searchCriteriaService.getExpansionPanel();
      this.searchCriteriaRequest = await this._searchCriteriaService.getFilters();
      this.cesReportId = typeof (this.searchCriteriaRequest.urcDocumentNumber) != 'undefined' && this.searchCriteriaRequest.urcDocumentNumber != null ? this.searchCriteriaRequest.urcDocumentNumber : '';
      if (this.searchCriteriaRequest && !this._searchCriteriaService.checkValidity(this.searchCriteriaRequest)) {
        await this.onSearch();
      }
    }
    else {
      await this.loadFiltersAndInitialization();
      this.cesReportId = this.searchCriteriaRequest.urcDocumentNumber;
    }

    this.subscriptionList.push(this.uploadUtilityService.searchResultConnectionDeleted.subscribe(connectionId => {
      if (connectionId) {
        this.successMessage = 'Connection is deleted successfully';
        const data = this.candidateConnectionResults;
        data.filter((x, index) => {
          if (+x.connectionId === connectionId) {
            data.splice(index, 1);
            return true;
          }
          return false;
        });
      }
    }));
  }

  async reloadFiltersAffectedByUOM() {
    //do not load the filters afected by UOM, if filters and expansion panel list exists in the session storage
    if (this._searchCriteriaService.checkFiltersAvailable() && !this._runId) {
      return;
    }
    let sizeCheckedItemsIndex = this.getSizeFilterCheckedItems();
    let yieldCheckedIndex = this.getYieldFilterCheckedItems();

    //remove size and yield from the filter's list
    this.expansionFilters = this.expansionFilters.filter(obj => obj.title != Constants.sizeTitle && obj.title != Constants.YieldTitle);

    //load size and yield applies search again if needed
    await this.loadSize(sizeCheckedItemsIndex);
    await this.loadYield(yieldCheckedIndex);

    if (this.isToShowViewDetails) {
      this.viewDetails(this.viewDetailsConnectionId);
    }
  }

  async loadThreadCompound() {
    let responseData = await this._inputDataService.getThreadCompoundTypes();

    let threadCompoundOptions = [];
    responseData.data.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      threadCompoundOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: threadCompoundOptions, title: Constants.threadCompoundTitle, usecheckbox: true, sort: 7, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);

  }
  async loadMaterial() {
    var responseData = await this._inputDataService.getMaterialTypes();
    let materialOptions = [];

    responseData.data.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      materialOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: materialOptions, title: Constants.materialTypeTitle, usecheckbox: true, sort: 4, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);
  }
  async loadConnectionType() {
    var responseData = await this._inputDataService.getConnectionTypes();
    let connectionTypeOptions = [];

    responseData.data.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      connectionTypeOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: connectionTypeOptions, title: Constants.connectionTypeTitle, usecheckbox: true, sort: 5, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);
  }

  async loadAdditionalParams() {
    const responseData = await this._inputDataService.getAdditonalFilter();
    let connectionTypeOptions = [];

    const distinctConnectionAnalysisDates = responseData.data['distinctConnectionAnalysisDate'];
    const formattedDates = distinctConnectionAnalysisDates.map(dateStr => new Date(dateStr))
      .sort((a, b) => b.getTime() - a.getTime())
      .map(date => this.datePipe.transform(date, 'dd/MM/yyyy')); //
    console.log(formattedDates);
    formattedDates.forEach((data) => {
      const obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      connectionTypeOptions.push(obj);
    });
    const sortedData = connectionTypeOptions.sort();
    const expansionFilter: IExpansionPanelModel = { items: sortedData, title: 'Connection Analysis Date', usecheckbox: true, sort: 10, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);


    let additionalQualitificationComment = [];

    const distinctAdditionalQualificationComment = responseData.data['distinctAdditionalQualificationComment'];
    distinctAdditionalQualificationComment.forEach(function (data) {

      if (data) {
        const obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
        additionalQualitificationComment.push(obj);
      }



    });
    const expansionFilterDistinctAddQualitificationComment: IExpansionPanelModel = { items: additionalQualitificationComment, title: 'Additional Qualification Comment', usecheckbox: true, sort: 11, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilterDistinctAddQualitificationComment);

    let distinctCoupleBox = [];

    const distinctCoupleBoxData = responseData.data['distinctCouplingBox'];
    distinctCoupleBoxData.forEach(function (data) {
      if (data) {
        const obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
        distinctCoupleBox.push(obj);
      }



    });
    const distinctCouplingBoxModel: IExpansionPanelModel = { items: distinctCoupleBox, title: 'Coupling/Box', usecheckbox: true, sort: 12, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(distinctCouplingBoxModel);

    let distinctGrade = [];

    const distinctGradeData = responseData.data['distinctGrade'];
    distinctGradeData.forEach(function (data) {
      if (data) {
        const obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
        distinctGrade.push(obj);
      }



    });
    const distinctGradeModel: IExpansionPanelModel = { items: distinctGrade, title: 'Grade', usecheckbox: true, sort: 12, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(distinctGradeModel);


    let distinctPinCoatTypeNameInfo = [];

    const distinctPinCoatTypeName = responseData.data['distinctPinCoatTypeName'];
    distinctPinCoatTypeName.forEach(function (data) {
      if (data) {
        const obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
        distinctPinCoatTypeNameInfo.push(obj);
      }



    });
    const distinctPinCoatTypeNameData: IExpansionPanelModel = { items: distinctPinCoatTypeNameInfo, title: 'Pin', usecheckbox: true, sort: 13, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(distinctPinCoatTypeNameData);


    let distinctTestCommentInfo = [];

    const distinctTestCommentInfoData = responseData.data['distinctTestComment'];
    distinctTestCommentInfoData.forEach(function (data) {
      if (data) {
        const obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
        distinctTestCommentInfo.push(obj);
      }


    });
    const distinctTestCommentModel: IExpansionPanelModel = { items: distinctTestCommentInfo, title: 'Test Comments', usecheckbox: true, sort: 14, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(distinctTestCommentModel);
  }


  loadActive() {
    let activeOptions = [];
    this.activeList.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      activeOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: activeOptions, title: Constants.activeTitle, usecheckbox: true, sort: 9, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);
  }

  //checkedItems is used when refresh UOM, to keep all checkboxes that were checked
  async loadSize(checkedItems: number[]) {
    let responseData = await this._inputDataService.getOuterDiameter();
    let sizeOptions = [];
    let index = 0;

    responseData.data.forEach(function (data) {
      let outerDiameterChildren: IExpansionPanelChildItem[] = [];
      var obj: IExpansionPanelItem = { value: data.outerDiameterTitle.toString(), name: data.outerDiameterTitle.toString(), checked: false, items: outerDiameterChildren };

      if (checkedItems.length > 0 && checkedItems.find(p => p == index) != null) {
        obj.checked = true;
      }

      index++;

      data.outerDiameters.forEach((outerDiameter) => {
        outerDiameter.wallThicknessAndWeight.forEach((wallThicknessAndWeight) => {
          var nameChild = `${outerDiameter.outerDiameter} ${outerDiameter.lengthUnit} by ${wallThicknessAndWeight.wallThickness} ${outerDiameter.lengthUnit} (${wallThicknessAndWeight.weight} ${outerDiameter.unitWeight})`;
          var valueChild = outerDiameter.outerDiameter + "|" + wallThicknessAndWeight.wallThickness + "|" + wallThicknessAndWeight.weight;
          let childSize = { checked: false, name: nameChild, value: valueChild, isToUseJustValueAsId: true };
          if (checkedItems.length > 0 && checkedItems.find(p => p == index) != null) {
            childSize.checked = true;
          }
          outerDiameterChildren.push(childSize);
          index++;
        });
      });

      obj.items = outerDiameterChildren;
      sizeOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: sizeOptions, title: Constants.sizeTitle, usecheckbox: true, sort: 1, enableSecondLevel: true, icon: '' };
    this.expansionFilters.push(expansionFilter);

    //Update the searchcriteria request, since it was reload by changing the UOM.
    if (checkedItems.length > 0) {
      this.setSearchCriteriaRequestBasedOnExpansionFilter(expansionFilter);
    }
  }

  async loadYield(checkedItems: number[]) {
    let index = 0;
    let responseData = await this._inputDataService.getAvailableYieldTypes();

    let YieldOptions = [];
    responseData.data.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data.yield.toString(), name: data.yield + " " + data.unit, checked: false, items: [] };
      if (checkedItems.length > 0 && checkedItems.find(p => p == index) != null) {
        obj.checked = true;
      }

      YieldOptions.push(obj);
      index++;
    });
    var expansionFilter: IExpansionPanelModel = { items: YieldOptions, title: Constants.YieldTitle, usecheckbox: true, sort: 2, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);
    //Update the searchcriteria request, since it was reload by changing the UOM.
    if (checkedItems.length > 0) {
      this.setSearchCriteriaRequestBasedOnExpansionFilter(expansionFilter);
    }
  }

  async loadConnectionUses() {
    var responseData = await this._inputDataService.getConnectionUse();
    let connectionUseOptions = [];

    responseData.data.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      connectionUseOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: connectionUseOptions, title: Constants.connectionUseTitle, usecheckbox: true, sort: 8, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);
  }
  async loadEvaluation() {
    var responseData = await this._inputDataService.getEvaluation();
    let materialOptions = [];

    responseData.data.forEach(function (data) {
      var obj: IExpansionPanelItem = { value: data, name: data, checked: false, items: [] };
      materialOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: materialOptions, title: Constants.evaluationTitle, usecheckbox: true, sort: 6, enableSecondLevel: false, icon: '' };
    this.expansionFilters.push(expansionFilter);
  }
  showDisclaimerPopupForDL: boolean;
  share2DrillPlanConstants = Share2DrillPlanMessages;
  share2DrillPlanDisclaimerForSearchCaseConstants =
    Share2DrillPlanDisclaimerForSearchCase;

    share2DrillPlanDisclaimerForResetCase =
    Share2DrillPlanDisclaimerForResetCase;
  showDisclaimerPopupForResetDLCase: boolean;
  

  async loadSortBy() {
    this.sortByResponse = await this._inputDataService.getSortBy();
  }

  async showDisclaimerMessageForDLCase(showDrillPlanSearchDisclaimer: boolean) {
    if(showDrillPlanSearchDisclaimer){
      this.performSearch(this.searchCriteriaRequest);
    }
    this.showDisclaimerPopupForDL = false;
  }

  async showDisclaimerMessageForDLResetCase(showDrillPlanSearchDisclaimerReset: boolean) {
    if(showDrillPlanSearchDisclaimerReset){
      this._loadCaseService.saveDrillPlanFlag(false);
      this.processFilterReset();
    }
    this.showDisclaimerPopupForResetDLCase = false;
  }

  async loadManufacturerandConnection() {
    var responseData = await this._inputDataService.getManufacturerAndConnection();
    let ManufacturerOptions = [];
    responseData.data.forEach(function (item: IManufacturerFilterResponseItem) {
      let manufacturerChildren: IExpansionPanelChildItem[] = [];
      item.connections.forEach(function (connection) {
        manufacturerChildren.push({ checked: false, name: connection.connectionName, value: connection.connectionName, isToUseJustValueAsId: true });
      });
      var obj: IExpansionPanelItem = { value: item.manufacturers.toString(), name: item.manufacturers.toString(), checked: false, items: manufacturerChildren };
      ManufacturerOptions.push(obj);
    });
    var expansionFilter: IExpansionPanelModel = { items: ManufacturerOptions, title: Constants.ManufAndConnection, usecheckbox: true, sort: 3, enableSecondLevel: true, icon: '' };
    this.expansionFilters.push(expansionFilter);
  }
  onSearchSet() {
    if (localStorage.getItem('AddNewConnection') == "true") {
      localStorage.setItem('AddNewConnection', '');
      this.addConnection = false;
    }
  }
  async onSearch(criteria?: SearchCriteriaRequest, isManualTrigger: boolean = false) {
    let criteriaToBeUsed = criteria ? criteria : this.searchCriteriaRequest;

    if (this._loadCaseService.isFromDrillPlan && this.initialCriteriaFromDP) {
      const isRequestHandled = this.handleDrillPlanCase(criteriaToBeUsed, isManualTrigger);
      if (isRequestHandled) {
        this.performSearch(criteriaToBeUsed);
      }
    } else {
      this.performSearch(criteriaToBeUsed);
    }
  }

  performSearch(criteriaToBeUsed: ISearchCriteriaRequest) {
    this.loadSortBy();
    criteriaToBeUsed.unitOfMeasure = UnitOfMeasure[this._unitOfMeasureService.getUnitOfMeasure()];
    this.resetResults();

    if (this.searchCriteriaRequest.urcDocumentNumber)
      this.searchCriteriaRequest.urcDocumentNumber = '';
    if (this.cesReportId) {
      if (isValidTextWithSomeSpecialCharacterRestrictions(this.cesReportId)) {
        this.searchCriteriaRequest.urcDocumentNumber = this.cesReportId;
      }
      else {
        this._alertErrorService.errorMessage.next(Constants.SpecialCharacterError);
        return;
      }
    }
    this._searchCriteriaService.search(criteriaToBeUsed).subscribe((responseData) => {
      this.searchCriteriaRequestTitleResult = { ...this.searchCriteriaRequest };
      this.candidateConnectionResults = responseData;
      this.candidateConnectionResults.forEach((item) => {
        item.manufacturerLogo = this.uploadUtilityService.getManufacturerLogo(item.manufacturer);
      });

      this.candidateConnectionResultsWithoutSort = this.candidateConnectionResults.slice();
      this.pageSelected = 1;
      this.recalculatePagination();
      this.loadFilterChip();
      this.saveSearchFilters(criteriaToBeUsed);
      if (this.isFromInitialSearchForDP) {
        if(!this._loadCaseService.getInitialSearchFromDP){
        this._loadCaseService.saveInitialSearchFromDP(criteriaToBeUsed);
        this.initialCriteriaFromDP = criteriaToBeUsed;
        }else{
          this.initialCriteriaFromDP = this._loadCaseService.getInitialSearchFromDP
        }
        this.isFromInitialSearchForDP = false;
      }
      if(this._loadCaseService.isFromDrillPlan){
        this.handleDrillPlanCase(criteriaToBeUsed);
      }
    });

    this._measureService.logUsage("Apply Clicked").subscribe();
  }

  handleDrillPlanCase(currentCriteria: ISearchCriteriaRequest, isManualSearch?: boolean): boolean {
    let isSearchResetCaseHandled = false;
    if (this._loadCaseService.isFromDrillPlan && this.initialCriteriaFromDP) {

      const initialOuterDiameter: IOuterDiameterWallThicknessAndWeight[] = this.initialCriteriaFromDP.outerDiameter;
      const currentOuterDiameter: IOuterDiameterWallThicknessAndWeight[] = currentCriteria?.outerDiameter;

      this._searchCriteriaService.isShareToDrillPlanEnabled = this.areArraysEqual(initialOuterDiameter, currentOuterDiameter) && this.arePressureValueTensionFromDPEqual();

      if (isManualSearch && !this._searchCriteriaService.isShareToDrillPlanEnabled) {
        this.showDisclaimerPopupForDL = true;
      }else if (!isManualSearch && !this._searchCriteriaService.isShareToDrillPlanEnabled){
        isSearchResetCaseHandled = true;
      }

    }
    return isSearchResetCaseHandled;
  }

  areArraysEqual(arr1: IOuterDiameterWallThicknessAndWeight[], arr2: IOuterDiameterWallThicknessAndWeight[]): boolean {
    let test = true;
    if(arr1.length == 0 || arr2.length == 0) {
      return true;
    }

    if (arr1.length !== arr2.length) {
      test = false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].outerDiameter !== arr2[i].outerDiameter ||
        arr1[i].wallThickness !== arr2[i].wallThickness ||
        arr1[i].weight !== arr2[i].weight) {
          test = false;
      }
    }

    return test;
  }

  private arePressureValueTensionFromDPEqual(): boolean {
    let isPressureTensionValuesEqual = false;
    if (this._loadCaseService.isFromDrillPlan) {
      const loadsSectionsDP: section[] = this._loadCaseService.getLoadsLocalStorageForDrillPlanCase();
      const updatedLoadsSections: section[] = this._loadCaseService.getLoadsLocalStorage();
  
      if (loadsSectionsDP && loadsSectionsDP.length > 0) {
        const drillPlanSection = loadsSectionsDP[0]; // as drillplan returns only one section
        const drillPlanLoadPressureTensionData = drillPlanSection.loadArray.flatMap(load => load.pressureAndTensionList);
  
        if (drillPlanLoadPressureTensionData) {
          const currentPressureTensionList = updatedLoadsSections[0]?.loadArray.flatMap(load => 
              load.pressureAndTensionList.map(item => ({
                pressure: item.pressure,
                tension: item.tension,
                imported: false
              }))
            )
  
          isPressureTensionValuesEqual = this.areArraysEqualForPressureTemp(currentPressureTensionList, drillPlanLoadPressureTensionData);
        }
      }
    }
    return isPressureTensionValuesEqual;
  }
  
areArraysEqualForPressureTemp(arr1: pressureAndTension[], arr2: pressureAndTension[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].pressure !== arr2[i].pressure || arr1[i].tension !== arr2[i].tension) {
        return false;
      }
    }
  
    return true;
  }


  updateExpansionFilter(expansionFilter: IExpansionPanelModel) {
    if (this.adminUser) {
      this.searchCriteriaRequest.active = true;
      this.searchCriteriaRequest.inactive = true;
    }
    else {
      this.searchCriteriaRequest.active = true;
      this.searchCriteriaRequest.inactive = false;
    }
    this.setSearchCriteriaRequestBasedOnExpansionFilter(expansionFilter);
  }
  //This method gets the filter values based on user selection.
  setSearchCriteriaRequestBasedOnExpansionFilter(expansionFilter: IExpansionPanelModel) {
    var checkedItems = expansionFilter.items.filter(x => { return x.checked == true }).map(x => x.value);

    if (expansionFilter.title === Constants.threadCompoundTitle) {
      this.searchCriteriaRequest.threadCompoundApplied = checkedItems.toString();
    }

    // Filters with 1 level
    if (expansionFilter.title === Constants.connectionTypeTitle ||
      expansionFilter.title === Constants.materialTypeTitle ||
      expansionFilter.title === Constants.connectionUseTitle ||
      expansionFilter.title === Constants.evaluationTitle ||
      expansionFilter.title === Constants.activeTitle) {

      if (expansionFilter.title === Constants.materialTypeTitle) {
        this.searchCriteriaRequest.isCarbonSteel = false;
        this.searchCriteriaRequest.isCRA = false;
      }
      if (expansionFilter.title === Constants.connectionTypeTitle) {
        this.searchCriteriaRequest.isThreadedAndCoupled = false;
        this.searchCriteriaRequest.isIntegralJoint = false;
        this.searchCriteriaRequest.isSemiFlush = false;
        this.searchCriteriaRequest.isFlushJoint = false;
      }

      if (expansionFilter.title === Constants.evaluationTitle) {
        this.searchCriteriaRequest.isFullyEvaluated = false;
      }
      if (expansionFilter.title === Constants.connectionUseTitle) {
        this.searchCriteriaRequest.highTorqueOnly = false;
      }
      var checkedItemsWithoutHighTorque: Array<ConnectionUses> = [];
      var checkedEvaluation: Array<Evaluation> = [];
      checkedItems.forEach((item) => {
        switch (item) {
          case Constants.ThreadedAndCoupled:
            this.searchCriteriaRequest.isThreadedAndCoupled = true;
            break;
          case Constants.IntegralJoint:
            this.searchCriteriaRequest.isIntegralJoint = true;
            break;
          case Constants.SemiFlushJoint:
            this.searchCriteriaRequest.isSemiFlush = true;
            break;
          case Constants.FlushJoint:
            this.searchCriteriaRequest.isFlushJoint = true;
            break;
          case Constants.carbonSteel:
            this.searchCriteriaRequest.isCarbonSteel = true;
            break;
          case Constants.CRA:
            this.searchCriteriaRequest.isCRA = true;
            break;
          case Constants.Casing:
            checkedItemsWithoutHighTorque.push(ConnectionUses.Casing);
            break;
          case Constants.Casing_Tubing:
            checkedItemsWithoutHighTorque.push(ConnectionUses.Casing_Tubing);
            break;
          case Constants.Tubing:
            checkedItemsWithoutHighTorque.push(ConnectionUses.Tubing);
            break;
          case Constants.HighTorque:
            this.searchCriteriaRequest.highTorqueOnly = true;
            break;
          case Active.Yes:
            this.searchCriteriaRequest.active = true;
            if (!checkedItems.includes(Active.No))
              this.searchCriteriaRequest.inactive = false;
            break;
          case Active.No:
            this.searchCriteriaRequest.inactive = true;
            if (!checkedItems.includes(Active.Yes))
              this.searchCriteriaRequest.active = false;
            break;
        }

        switch (item.trim().replace(/\s/g, "")) {
          case Evaluation.FEAOnly:
            checkedEvaluation.push(Evaluation.FEAOnly);
            break;
          case Evaluation.FullyEvaluated:
            checkedEvaluation.push(Evaluation.FullyEvaluated);
            break;
          case Evaluation.FullyEvaluatedLimitedSealabilityEnvelope:
            checkedEvaluation.push(Evaluation.FullyEvaluatedLimitedSealabilityEnvelope);
            break;
          case Evaluation.PartiallyEvaluated:
            checkedEvaluation.push(Evaluation.PartiallyEvaluated);
            break;
        }
      });
      this.searchCriteriaRequest.connectionUse = checkedItemsWithoutHighTorque;
      this.searchCriteriaRequest.evaluation = checkedEvaluation;
    }
    //Filters with 2 levels
    if (expansionFilter.title === Constants.sizeTitle) {
      this.searchCriteriaRequest.outerDiameter = [];
      expansionFilter.items.forEach((item) => {
        let checkedOuterDiameterItems = item.items.filter(x => { return x.checked == true }).map(x => x.value);
        checkedOuterDiameterItems.forEach((chOuterDiamter) => {
          let data = chOuterDiamter.split('|');
          let outerDiamterObject: OuterDiameterWallThicknessAndWeight = { outerDiameter: +data[0], wallThickness: +data[1], weight: +data[2] }
          this.searchCriteriaRequest.outerDiameter.push(outerDiamterObject);
        });
      })
    }

    if (expansionFilter.title === Constants.YieldTitle) {
      this.searchCriteriaRequest.yield = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.yield.push(parseInt(item));
      });
    }

    if (expansionFilter.title === Constants.additionalQualificationCommentTitle) {
      this.searchCriteriaRequest.additionalQualificationComments = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.additionalQualificationComments.push(item);
      });
    }

    if (expansionFilter.title === Constants.connectionAnalysisDateTitle) {
      this.searchCriteriaRequest.connectionAnalysisDates = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.connectionAnalysisDates.push(item);
      });
    }

    if (expansionFilter.title === Constants.couplingBoxTitle) {
      this.searchCriteriaRequest.couplingBoxs = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.couplingBoxs.push(item);
      });
    }

    if (expansionFilter.title === Constants.gradeTitle) {
      this.searchCriteriaRequest.grades = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.grades.push(item);
      });
    }

    if (expansionFilter.title === Constants.pinTitle) {
      this.searchCriteriaRequest.pins = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.pins.push(item);
      });
    }

    if (expansionFilter.title === Constants.testCommentTitle) {
      this.searchCriteriaRequest.testComments = [];
      checkedItems.forEach((item) => {
        this.searchCriteriaRequest.testComments.push(item);
      });
    }

    if (expansionFilter.title === Constants.ManufAndConnection) {
      this.searchCriteriaRequest.manufacturer = "";
      this.searchCriteriaRequest.connectionName = "";
      let checkedManufacturerItems = '';
      let checkedConn = '';
      checkedItems.forEach((item) => {
        checkedManufacturerItems = checkedManufacturerItems + "," + item;
        this.searchCriteriaRequest.manufacturer = checkedManufacturerItems.substring(1, checkedManufacturerItems.length);
      });
      expansionFilter.items.forEach((item) => {
        let checkedConnectionItems = item.items.filter(x => { return x.checked == true }).map(x => x.value);
        checkedConnectionItems.forEach((chConnection) => {
          checkedConn = checkedConn + "," + chConnection;
          this.searchCriteriaRequest.connectionName = checkedConn.substring(1, checkedConn.length);
        });
      });
    }
  }

  sortedExpansionFilters() {
    let sortedExpansioFilters = this.expansionFilters.sort((a, secondItem) => a.sort - secondItem.sort);
    return sortedExpansioFilters;
  }

  onSortByChange(value) {
    this.searchCriteriaRequest.sortBy = value;
    this.candidateConnectionResults = this.candidateConnectionResultsWithoutSort.slice();
    switch (value) {
      case SortBy.OD:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.od - b.od);
        break;
      case SortBy.Manufacturer:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer));
        break;
      case SortBy.Yield:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.yield - b.yield);
        break;
      case SortBy.ConnectionName:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.connectionName.localeCompare(b.connectionName));
        break;
      case SortBy.Grade:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.gradesReportString.localeCompare(b.gradesReportString));
        break;
      case SortBy.WallThickness:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.wallThickness - b.wallThickness);
        break;
      case SortBy.Weight:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.weight - b.weight);
        break;
      default:
        this.candidateConnectionResults = this.candidateConnectionResults.sort((a, b) => a.od - b.od);
    }

    this.offset = this.viewCount * (this.pageSelected - 1);
    this.candidateConnectionResultsPagination = this.candidateConnectionResults.slice(this.offset, this.offset + this.viewCount);
  }

  loadFilterChip() {
    this.filterChipItems = [];

    this.expansionFilters.forEach((expansionFilter) => {
      //Filters with 1 level
      if (!expansionFilter.enableSecondLevel) {
        var checkedItems = expansionFilter.items.filter(x => { return x.checked == true }).map(x => x.name);
        if (checkedItems.length > 0) {
          let itemTitle: IFilterChipItem = {
            isTitle: true,
            name: expansionFilter.title,
            mainFilterName: '',
            parentName: '',
            allChildrenChecked: false
          };
          this.filterChipItems.push(itemTitle);

          checkedItems.forEach((checkedItem) => {
            let item: IFilterChipItem =
            {
              isTitle: false,
              name: checkedItem,
              mainFilterName: expansionFilter.title,
              parentName: '',
              allChildrenChecked: false
            }
            this.filterChipItems.push(item);
          });
        }
      }
      else //Filters with 2 level      
      {
        expansionFilter.items.forEach((secondLevelExpansionFilter) => {

          let checkedItems = secondLevelExpansionFilter.items.filter(x => { return x.checked == true }).map(x => x.name);

          //Add Title if there's any item checked ex: Manufacturer and Connection
          if (checkedItems.length > 0 && !this.filterChipItems.find(x => x.name == expansionFilter.title)) {
            let itemTitle: IFilterChipItem = {
              isTitle: true,
              name: expansionFilter.title,
              mainFilterName: expansionFilter.title,
              parentName: '',
              allChildrenChecked: false
            };
            this.filterChipItems.push(itemTitle);
          }

          //All children checked, send just the parentName
          if (checkedItems.length == secondLevelExpansionFilter.items.length) {
            let itemFilter: IFilterChipItem =
            {
              isTitle: false,
              name: secondLevelExpansionFilter.name,
              mainFilterName: expansionFilter.title,
              parentName: secondLevelExpansionFilter.name,
              allChildrenChecked: true
            }
            this.filterChipItems.push(itemFilter);
          }
          else {
            checkedItems.forEach((checkedItem) => {
              let itemFilter: IFilterChipItem =
              {
                isTitle: false,
                name: checkedItem,
                mainFilterName: expansionFilter.title,
                parentName: secondLevelExpansionFilter.name,
                allChildrenChecked: false
              }
              this.filterChipItems.push(itemFilter);
            });
          }


        });
      }
    });
    //Add filter chip when CES document filter is applied.
    if (typeof (this.cesReportId) != 'undefined' && this.cesReportId != null && this.cesReportId != '') {
      let itemTitle: IFilterChipItem = {
        isTitle: true,
        name: Constants.docFilterTitle,
        mainFilterName: '',
        parentName: '',
        allChildrenChecked: false
      };
      this.filterChipItems.push(itemTitle);
      let item: IFilterChipItem =
      {
        isTitle: false,
        name: this.cesReportId,
        mainFilterName: Constants.docFilterTitle,
        parentName: '',
        allChildrenChecked: false
      }
      this.filterChipItems.push(item);
    }
  }

  closeFilter(filterChipItem: IFilterChipItem) {
    let expansionFilterWithClosedItem = this.expansionFilters.find(x => x.title == filterChipItem.mainFilterName);
    if (filterChipItem.mainFilterName == Constants.docFilterTitle) {
      this.cesReportId = '';
      this.loadFilterChip();
      if (this.filterChipItems.length == 0) {
        this.resetFilters();
      }
      else {
        this.onSearch();
      }
      return;
    }
    else if (!expansionFilterWithClosedItem.enableSecondLevel) {
      let removedItem = expansionFilterWithClosedItem.items.find(x => x.name == filterChipItem.name);
      removedItem.checked = false;
    }
    //Filter with 2 level
    else {
      let parentExpansionFilterItem = expansionFilterWithClosedItem.items.find(x => x.name == filterChipItem.parentName);

      if (filterChipItem.allChildrenChecked) {
        parentExpansionFilterItem.checked = false;
        parentExpansionFilterItem.items.forEach((childFilterToRemove) => {
          childFilterToRemove.checked = false;
        });
      }
      else {
        let childFilterToRemove = parentExpansionFilterItem.items.find(x => x.name == filterChipItem.name);
        childFilterToRemove.checked = false;
      }
    }

    this.updateExpansionFilter(expansionFilterWithClosedItem);
    this.loadFilterChip();
    if (this.filterChipItems.length == 0) {
      this.resetFilters();
    }
    else {
      this.onSearch();
    }
  }

  resetFilters() {
    if(this._loadCaseService.isFromDrillPlan && this.initialCriteriaFromDP) {
      const isRequestHandled = this.handleDrillPlanCase(this.searchCriteriaRequest, false);
      if (isRequestHandled) {
        this.showDisclaimerPopupForResetDLCase = true;
      }else{
        this.processFilterReset();
      }
    }else{
      this.processFilterReset();
    }
  }

  public processFilterReset() {
    this.searchCriteriaRequest = new SearchCriteriaRequest();
    this.cesReportId = "";
    this.expansionFilters.forEach((expansionFilter) => {
      //Filters with 1 level
      if (!expansionFilter.enableSecondLevel) {
        var checkedItems = expansionFilter.items.filter(x => { return x.checked == true; });

        checkedItems.forEach((checkedItem) => {
          checkedItem.checked = false;
        });
      }
      else //Filters with 2 level      
      {
        expansionFilter.items.forEach((secondLevelExpansionFilter) => {
          let checkedItems = secondLevelExpansionFilter.items.filter(x => { return x.checked == true; });
          secondLevelExpansionFilter.checked = false;
          checkedItems.forEach((checkedItem) => {
            checkedItem.checked = false;
          });
        });
      }
    });
    this.resetResults();
    //Remove the saved filters and expansion panel list
    this._searchCriteriaService.removeFilters();
    this.router.navigate(['/search']);
  }

  resetResults() {
    this.filterChipItems = [];
    this.candidateConnectionResults = [];
    this.candidateConnectionResultsPagination = [];
    this.candidateConnectionResultsWithoutSort = [];
    this.numberOfPages = 0;
  }

  pageClick(index) {
    this.pageSelected = index;
    this.offset = this.viewCount * (index - 1);
    this.candidateConnectionResultsPagination = this.candidateConnectionResults.slice(this.offset, this.offset + this.viewCount);
  }

  recalculatePagination() {
    this.numberOfPages = Math.ceil(this.candidateConnectionResultsWithoutSort.length / this.viewCount);
    this.offset = this.viewCount * (this.pageSelected - 1);

    if (this.numberOfPages >= 2) {
      this.candidateConnectionResultsPagination = this.candidateConnectionResultsWithoutSort.slice(this.offset, this.offset + this.viewCount);
    }
    else {
      this.candidateConnectionResultsPagination = this.candidateConnectionResultsWithoutSort.slice();
    }
  }

  onViewChange(value) {
    if (isNumeric(value)) {
      this.viewCount = parseInt(value);
      this.pageSelected = 1;
      this.recalculatePagination();
    }
    else {
      this.candidateConnectionResultsPagination = this.candidateConnectionResults.slice();
      this.numberOfPages = 0;
    }
  }

  async openPDF() {
    this.filterChipItemsToPDF = '';
    this.candidateConnectionResultsWithEnvelope = await this._searchCriteriaService.searchWithGasEnvelope(this.candidateConnectionResults.map(p => p.connectionId));
    let count = 0;
    this.filterChipItems.forEach((filterChip) => {
      if (filterChip.isTitle)
        this.filterChipItemsToPDF += " " + filterChip.name + ":";
      else {
        if (filterChip.parentName && filterChip.mainFilterName != Constants.sizeTitle)
          this.filterChipItemsToPDF += " " + filterChip.parentName + " | " + filterChip.name;
        else
          this.filterChipItemsToPDF += " " + filterChip.name;

        if (this.filterChipItems[count + 1] !== null && this.filterChipItems[count + 1] !== undefined && !this.filterChipItems[count + 1].isTitle)
          this.filterChipItemsToPDF += ","
      }
      count++;
    });

    // Wait for angular render before taking the element
    await sleep(100);
    const pdfTable = document.getElementById('htmlData');
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = {
      content: html,
      pageOrientation: 'landscape',
      info: {
        title: 'CandidateConnectionResults'
      }
    };
    pdfMake.createPdf(documentDefinition).download("CandidateConnectionResults");
  }

  getSizeFilterCheckedItems(): number[] {
    let sizeFilter = this.expansionFilters.find(p => p.title == Constants.sizeTitle);

    let sizeCheckedItemsIndex = [];
    let index = 0;
    if (sizeFilter != null) {
      sizeFilter.items.forEach((size) => {
        if (size.checked)
          sizeCheckedItemsIndex.push(index);
        index++;
        size.items.forEach((childSize) => {
          if (childSize.checked)
            sizeCheckedItemsIndex.push(index);
          index++;
        })
      });
    }
    return sizeCheckedItemsIndex;
  }

  getYieldFilterCheckedItems(): number[] {
    let yieldFilter = this.expansionFilters.find(p => p.title == Constants.YieldTitle);
    let index = 0;

    let yieldCheckedIndex = [];
    if (yieldFilter != null) {
      yieldFilter.items.forEach((item) => {
        if (item.checked)
          yieldCheckedIndex.push(index);
        index++;
      })
    }
    return yieldCheckedIndex;
  }

  viewDetails(connectionId: string) {
    this.viewDetailsConnectionId = connectionId;
    this._searchCriteriaService.searchWithAdditionalInfo([connectionId]).subscribe((responseData) => {
      this.candidateConnectionResultViewDetails = responseData[0];
      this.candidateConnectionResultViewDetails.manufacturerLogo = this.uploadUtilityService.getManufacturerLogo(this.candidateConnectionResultViewDetails.manufacturer);
      if (this.candidateViewDetailsComponent) {
        this.candidateViewDetailsComponent.candidateConnection = this.candidateConnectionResultViewDetails;
        this.candidateViewDetailsComponent.createLiquidChart();
        this.candidateViewDetailsComponent.createGasChart();
        this.candidateViewDetailsComponent.createStructuralChart();
      }
      this.isToShowViewDetails = true;

    });
  }

  onBackClick(deletionAction: IDeleteConnection) {
    this.isToShowViewDetails = false;
    if (deletionAction?.isDeletion) {
      this.onSearch();
    }
  }

  async loadFiltersAndInitialization() {
    await Promise.all([this.loadThreadCompound(), this.loadMaterial(), this.loadConnectionType(), this.loadConnectionUses(), this.loadManufacturerandConnection(),
    this.loadEvaluation(), this.reloadFiltersAffectedByUOM()]);
    this.expansionPanelLoaded.next(true);
    if (this.adminUser) {
      this.loadActive();
      this.loadAdditionalParams();
    }
    this.searchCriteriaRequest.outerDiameter = [];
    this.searchCriteriaRequest.yield = [];
    this.cesReportId = '';
  }
  saveSearchFilters(criteria?: SearchCriteriaRequest) {
    let criteriaToBeSaved = criteria ? criteria : this.searchCriteriaRequest;
    this._searchCriteriaService.saveFilters(criteriaToBeSaved, this.expansionFilters);
  }

  selectExpansionPanelFiltersBasedOnSearchCriteria(criteria: SearchCriteriaRequest) {
    if (criteria.outerDiameter) {
      criteria.outerDiameter.forEach(od => {
        let odExpansionToBeUpdated = this.expansionFilters.find(p => p.title === Constants.sizeTitle);

        if (odExpansionToBeUpdated) {
          odExpansionToBeUpdated.items.forEach(parentSize => {
            let anyChildChecked = false;
            let itemChecked = od.outerDiameter + "|" + od.wallThickness + "|" + od.weight;

            let itemToBeChecked = parentSize.items.find(p => p.value == itemChecked);
            if (itemToBeChecked) {
              anyChildChecked = true;
              itemToBeChecked.checked = true;
            }

            if (anyChildChecked)
              parentSize.checked = true;
          });
        }
      });
    }

    if (criteria.yield) {
      let yieldExpansionFilter = this.expansionFilters.find(p => p.title === Constants.YieldTitle);
      if (yieldExpansionFilter) {
        criteria.yield.forEach(criteriaYield => {
          let yieldToBeChecked = yieldExpansionFilter.items.find(p => +p.value === criteriaYield);
          if (yieldToBeChecked)
            yieldToBeChecked.checked = criteriaYield === +yieldToBeChecked.value;
          else {
            const index = criteria.yield.indexOf(criteriaYield);
            criteria.yield.splice(index, 1);
          }
        });
      }
    }
    this.onSearch(criteria);
  }

  prepareAndSaveLoadsFromTedIntegration(section: any, well: welldesc) {
    let removedFrom = [];
    var countLoad = 0;

    let wellName = removeNotAllowedSpecialCharacters(well.wellName);
    section.wellName = wellName.value;
    well.wellName = wellName.value;

    if (wellName.removed) {
      removedFrom.push('Well Name');
    }

    let caseDescription = removeNotAllowedSpecialCharacters(well.caseDescription);
    if (caseDescription.removed) {
      removedFrom.push('Case Description');
    }
    well.caseDescription = caseDescription.value;

    section.forEach(loadSection => {
      loadSection.loadArray.forEach((load) => {
        let loadComment = removeNotAllowedSpecialCharacters(load.comment);
        let loadName = removeNotAllowedSpecialCharacters(load.name);

        if (loadComment.removed || loadName.removed) {
          if (loadComment.removed) {
            if (!removedFrom.find(p => p === 'Load Comment')) {
              removedFrom.push('Load Comment');
            }
          }
          if (loadName.removed) {
            if (!removedFrom.find(p => p === 'Load Name')) {
              removedFrom.push('Load Name');
            }
          }
        }

        load.comment = loadComment.value;
        load.name = loadName.value;

      });
      countLoad += loadSection.loadArray.length
    });

    if (removedFrom.length > 0) {
      var message = Constants.TedIntegrationSpecialCharacter.replace("[0]", removedFrom.join(','));
      alert(message);
    }

    this._mainHeaderService.UpdateLoadCount(countLoad);
    this._loadCaseService.saveLoadsLocalStorage(section);
    if(this._loadCaseService.isFromDrillPlan){
      this._loadCaseService.saveLoadsLocalStorageForDrillPlanCase(section);
    }
    this._loadCaseService.savewellsLocalStorage(well);

  }

  closeSuccessMessage(): void {
    this.successMessage = '';
  }
}
