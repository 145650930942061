import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPoints } from '../app-config/endpoints';
import { UnitOfMeasureService } from './unitofmeasure.service';
import { Observable } from 'rxjs';
import { load } from '../../common/models/load.model';
import { section } from '../../common/models/section.model';
import { welldesc } from '../../common/models/welldesc.model';
import { IUnitAcronymResponse } from '../../common/models/responses/interfaces/IUnitAcronymResponse';
import { TabStorageService } from './tabstorage.service';
import { IExportToExcel, IExportToExcelRow } from '../../common/models/components/iExportToExcel';
import { Constants } from '../../common/constants/constant';
import { ISearchCriteriaRequest } from '../../common/models/requests/interfaces/isearchcriteriarequest';
import { SealabilityTypes } from '../../common/Enums/SealabilityTypes';

@Injectable({ providedIn: 'root' })
export class LoadCaseService {

  tabSpecificKey: string;
  copyLoadSectionsForDrillPlan: string;
  wellSpecificKey: string;
  isFromDrillPlanSessionKey: string;
  initialSearchFromDPSessionKey: string;


  constructor(private http: HttpClient, private unitOfMeasure: UnitOfMeasureService, private tabStorageService: TabStorageService) {
    this.tabSpecificKey = 'loadCasesKeyToSave_' + this.tabStorageService.getTabId();
    this.wellSpecificKey = 'wellKeyToSave_' + this.tabStorageService.getTabId();
    this.copyLoadSectionsForDrillPlan = 'copyLoadSectionsForDrillPlan_' + this.tabStorageService.getTabId();
    this.isFromDrillPlanSessionKey = 'IsFromDrillPlanSessionKey_' + this.tabStorageService.getTabId();
    this.initialSearchFromDPSessionKey = 'InitialSearchFromDPSessionKey' + this.tabStorageService.getTabId();
  }

  public saveDrillPlanFlag(isFromDrillPlan: boolean) {
    sessionStorage.setItem(this.isFromDrillPlanSessionKey, JSON.stringify(isFromDrillPlan));
  }

  saveInitialSearchFromDP(initialSearchFromDPSessionKey: ISearchCriteriaRequest) {
    sessionStorage.setItem(this.initialSearchFromDPSessionKey, JSON.stringify(initialSearchFromDPSessionKey));
  }

 get getInitialSearchFromDP() : ISearchCriteriaRequest {
   return JSON.parse(sessionStorage.getItem(this.initialSearchFromDPSessionKey));
  }

  get isFromDrillPlan(): boolean {
    return sessionStorage.getItem(this.isFromDrillPlanSessionKey) === 'true';
  }

  getEnvelopesType(): SealabilityTypes[] {
    return [SealabilityTypes.Gas, SealabilityTypes.Liquid, SealabilityTypes.Structural];
  }
  getSectionsConvertedByUOM(section: section[]): Observable<section[]> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(section);
    let endpoint = `${EndPoints.API_BASE_URL}/loadcase/getSectionsConvertedByUOM/${this.unitOfMeasure.getFromUnitOfMeasure()}/${this.unitOfMeasure.getUnitOfMeasure()}`;
    return this.http
      .post<section[]>(endpoint, body, { 'headers': headers });
  }
  getConvertedDataForPressureAndTensionLoad(load: load): Observable<load> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(load);
    let endpoint = `${EndPoints.API_BASE_URL}/loadcase/getconvertedload/${this.unitOfMeasure.getUnitOfMeasure()}`;
    return this.http
      .post<load>(endpoint, body, { 'headers': headers });
  }
  getAcronymForPressureTension(): Observable<IUnitAcronymResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/loadcase/getacronymforpressuretension/${this.unitOfMeasure.getUnitOfMeasure()}`;
    return this.http
      .get<IUnitAcronymResponse>(endpoint);
  }

  //save the loads in local
  public saveLoadsLocalStorage(sectionArray: section[]) {    
    sessionStorage.setItem(this.tabSpecificKey, JSON.stringify(sectionArray));
  }

  public saveLoadsLocalStorageForDrillPlanCase(sectionArray: section[]) {    
    sessionStorage.setItem(this.copyLoadSectionsForDrillPlan, JSON.stringify(sectionArray));
  }

  public getLoadsLocalStorageForDrillPlanCase() : section[] {    
    return JSON.parse(sessionStorage.getItem(this.copyLoadSectionsForDrillPlan));
  }

  public getLoadsLocalStorage(): section[] {
    let sectionArray: section[] = JSON.parse(sessionStorage.getItem(this.tabSpecificKey));
    return sectionArray;
  }

  //save the wells in local
  public savewellsLocalStorage(wellsData: welldesc) {
    sessionStorage.setItem(this.wellSpecificKey, JSON.stringify(wellsData));
  }

  public getwellsLocalStorage(): welldesc {
    let welldescdata: welldesc = JSON.parse(sessionStorage.getItem(this.wellSpecificKey));
    return welldescdata;
  }

  //this method converts the stored loads.
  public convertStoredLoads() {
    let savedSection: section[] = this.getLoadsLocalStorage();
    if (savedSection) {
      this.getSectionsConvertedByUOM(savedSection).subscribe(res => {
        this.saveLoadsLocalStorage(res);
      });
    }

  }

  generateBlankTemplateExportToExcel() {
    //Generate blank excel templates with default load and section
    let pressureAcr = this.unitOfMeasure.getUnitOfMeasure() == 'US' ? Constants.pressureUS : Constants.pressureMetric;
    let tensionAcr = this.unitOfMeasure.getUnitOfMeasure() == 'US' ? Constants.tensionUS : Constants.tensionMetric;
    let exportToExcel: IExportToExcel[] = [];
    let sheet: IExportToExcel = {
        sheetName: 'Section A', headerList: ['', 'Load Name', 'Load Comments', 'Sealability Envelopes', `Tension (${tensionAcr})`, `Pressure (${pressureAcr})`, 'UnitOfMeasure'], rows: [], wellDetailList: ['Well Name:','Case Description:']
    };
    for (var i = 0; i < 500; i++) {
      let sealabilityValue = i < Constants.sealability.length? Constants.sealability[i]:'';

      let formula = i >= 1 ? `=IF(OR(OR(NOT(ISBLANK(C${(i + 1)})),NOT(ISBLANK(D${(i + 1)})),NOT(ISBLANK(E${(i + 1)}))),OR(NOT(ISBLANK(C${i})),NOT(ISBLANK(D${i})),NOT(ISBLANK(E${i}))) ),ROW()-1,"")` : '';
      let exportToExcelRow: IExportToExcelRow = {
        columns: [{ formula: formula }, { content: i == 0 ? 'Load 1' : '' }, { content: '' }, { content: sealabilityValue }, { content: '' }, { content: '' }, { content: i == 0 ? this.unitOfMeasure.getUnitOfMeasure() : '' }]
      };
      sheet.rows.push(exportToExcelRow);
    }
    exportToExcel.push(sheet);
    return exportToExcel;
  }

}
