import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';
import { ISearchCriteriaRequest } from '../../../../common/models/requests/interfaces/isearchcriteriarequest';
import { SearchCriteriaRequest } from '../../../../common/models/requests/searchcriteriarequest';
import { Constants } from '../../../../common/constants/constant';
import { UploadUtilityService } from '../../pages/upload-utility/upload-utility.service';
import { DeleteConnection } from '../upload-utility/upload-utility-helper';
import { MsalService } from '@azure/msal-angular';
import { AlertErrorService } from 'app/service/tcdb/alert-error.service';
import { SearchCriteriaService } from 'app/service/tcdb/searchcriteria.service';
import { TabStorageService } from 'app/service/tcdb/tabstorage.service';
import { UnitOfMeasureService } from 'app/service/tcdb/unitofmeasure.service';

@Component({
  selector: 'app-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.css']
})
export class SearchResultItemComponent implements OnInit {
  @Input() candidateConnectionResultsPagination;
  @Input() searchCriteriaRequest: ISearchCriteriaRequest = new SearchCriteriaRequest();
  @Output() viewDetails = new EventEmitter<string>();
  isApplySearchExpanded: boolean;
  currentDeletedConnectionId: string;

  constructor(private searchCriteriaService: SearchCriteriaService, public uploadeUtilityService: UploadUtilityService, private _msalService: MsalService,
    public errorAlertService: AlertErrorService, public _tabStorageService:TabStorageService, private unitOfMeasureService:UnitOfMeasureService
  ) {
  }
  ngOnInit(): void {
    this.searchCriteriaService.isApplyFilterExpanded.subscribe(isApplySearchExpanded => {
      this.isApplySearchExpanded = isApplySearchExpanded;
    });
  }

  loadMaterialTitle() {
    let materialTitle = '';
    if (this.searchCriteriaRequest.isCarbonSteel) {
      materialTitle += ' ' + Constants.carbonSteel;
    }
    if (this.searchCriteriaRequest.isCRA) {
      materialTitle += ' ' + Constants.CRA;
    }
    return materialTitle;

  }

  loadConnectionTypeTitle() {
    let connectionTypeTitle = '';
    if (this.searchCriteriaRequest.isThreadedAndCoupled) {
      connectionTypeTitle += ' ' + Constants.ThreadedAndCoupled;
    }
    if (this.searchCriteriaRequest.isIntegralJoint) {
      connectionTypeTitle += ' ' + Constants.IntegralJoint;
    }
    if (this.searchCriteriaRequest.isSemiFlush) {
      connectionTypeTitle += ' ' + Constants.SemiFlushJoint;
    }
    if (this.searchCriteriaRequest.isFlushJoint) {
      connectionTypeTitle += ' ' + Constants.FlushJoint;
    }

    return connectionTypeTitle;

  }

  onViewDetails(id) {
    this.viewDetails.emit(id);
    this.uploadeUtilityService.isTableColumnsEditable.next(false);
    this.uploadeUtilityService.showCopyExcel.next(false);
    this.uploadeUtilityService.showAddButton.next(false);
    this.unitOfMeasureService.MetricDisabled.next(false);
  }
  showConfirmationModal: boolean = false;
  deleteConnectionConstants = DeleteConnection;



  deleteConnection(connectionId: string) {
    this.showConfirmationModal = true;
    this.currentDeletedConnectionId = connectionId;
  }
  showErrorMessage() {
    this.errorAlertService.errorMessage.next('An unknown error has occurred. Contact goto/WECsupport for assistance if the problem persists.');
  }


  deleteConnectionOnConfirmation(isDeleteConfimed: boolean) {
    if (isDeleteConfimed) {
      this.uploadeUtilityService.deleteConnection(+this.currentDeletedConnectionId).subscribe((response: any) => {
        if (response) {
          this.showConfirmationModal = false;
          
          const data = this.candidateConnectionResultsPagination;
          data.filter((x, index) => {
            if (x.connectionId === this.currentDeletedConnectionId) {
              data.splice(index, 1);
              return true; 
            }
            return false;
          });

          this.uploadeUtilityService.searchResultConnectionDeleted.next(+this.currentDeletedConnectionId);

        } else {
          this.showErrorMessage();
        }
      }, (_error) => {
        this.showErrorMessage();
      });
    } else {
      this.showConfirmationModal = false;
    }
  }

}
