import { Component, Input, OnInit } from '@angular/core';
import { UploadUtilityService } from '../../../pages/upload-utility/upload-utility.service';
import { COMMENTS, MATERIAL_GRADE, specimeN_TEST_TYPE_NM, SPECIMEN_TEST_TYPE_VTBLs, SPECIMEN_TYPE, TEST_DATE, TEST_FACILITY, TEST_PROCEDURE, tesT_PROCEDURE_NM, TEST_PROCEDURE_VTBLs, TEST_TYPE, TestInformation_Comments, TestInformation_Date, TestInformation_Facility, TestInformation_MaterialGrade, TestInformation_Procedure, TestInformation_Specimen, TestInformation_Test } from '../upload-utility-helper';
import { ITableHeader } from 'app/common/models/components/iTableHeader';
import { ITableRow, IColumn } from 'app/common/models/components/iTableRow';
import { ITestInformationDTO } from 'app/common/models/requests/interfaces/ConnectionRequest';
import { ITestSpecimen } from 'app/common/models/responses/interfaces/icandidateConnectionResult.interface';
import { IVTableDataMapper } from 'app/common/models/responses/interfaces/IVTableDataMapper.interface';
@Component({
  selector: 'app-test-information-section',
  templateUrl: './test-information-section.component.html',
  styleUrls: ['./test-information-section.component.css']
})
export class TestInformationSectionComponent implements OnInit {

  testInformationHeader: ITableHeader[] = [];
  testInformationColumsData: ITableRow[] = [];
  @Input() useAddRow: boolean = true;
  isToEdit = false;
  testInfoSpecificKey: string;
  constructor(private utilityService: UploadUtilityService) { }
  ngOnInit(): void {

    this.utilityService.getVTableDataByTableNames([TEST_PROCEDURE_VTBLs, SPECIMEN_TEST_TYPE_VTBLs]).subscribe(data => {

      this.setUpTestInformationHeaders(this.utilityService.mapToDropdownOptions(data?.vTableData[TEST_PROCEDURE_VTBLs], tesT_PROCEDURE_NM, tesT_PROCEDURE_NM), this.utilityService.mapToDropdownOptions(data?.vTableData[SPECIMEN_TEST_TYPE_VTBLs], specimeN_TEST_TYPE_NM, specimeN_TEST_TYPE_NM));

    });
  }
  private setUpTestInformationHeaders(procedureVTableData: IVTableDataMapper[], testVTableData: IVTableDataMapper[]) {
    //procedureVTableData.unshift({ value: '', label: '' });
    //testVTableData.unshift({ value: '', label: '' });
    this.testInformationHeader.push({
      name: TestInformation_Procedure, isNumeric: false, errorMessage: '', isDropdown: true, headerPropertyName: TEST_PROCEDURE, isRequired: true,
      options: procedureVTableData
    });
    this.testInformationHeader.push({ name: TestInformation_Specimen, isNumeric: false, errorMessage: '', headerPropertyName: SPECIMEN_TYPE, isRequired: true });
    this.testInformationHeader.push({ name: TestInformation_MaterialGrade, isNumeric: false, errorMessage: '', headerPropertyName: MATERIAL_GRADE, isRequired: true });
    this.testInformationHeader.push({
      name: TestInformation_Test, isNumeric: false, errorMessage: '', isDropdown: true, headerPropertyName: TEST_TYPE, isRequired: true, options: testVTableData
    });
    this.testInformationHeader.push({ name: TestInformation_Date, isNumeric: false, errorMessage: '', isDate: true, headerPropertyName: TEST_DATE });
    this.testInformationHeader.push({ name: TestInformation_Facility, isNumeric: false, errorMessage: '', headerPropertyName: TEST_FACILITY });
    this.testInformationHeader.push({ name: TestInformation_Comments, isNumeric: false, errorMessage: '', headerPropertyName: COMMENTS });
    this.updateEvelopeTypeCD(procedureVTableData, testVTableData);

  }

  private updateEvelopeTypeCD(procedureVTableData: IVTableDataMapper[], testVTableData: IVTableDataMapper[]): void {
    this.testInformationColumsData?.map(item => {
      item.tdColumn.forEach(col => {
        if (col.headerPropertyName === TEST_PROCEDURE) {
          col.options = procedureVTableData;
        }
        if (col.headerPropertyName === TEST_TYPE) {
          col.options = testVTableData;
        }
      });

    });
  }

  public saveTestInfoLocalStorage() {
    sessionStorage.setItem(this.testInfoSpecificKey, JSON.stringify(this.testInformationColumsData));
  }
  public getTestInfoLocalStorage() {
    let testSectionArray = JSON.parse(sessionStorage.getItem(this.testInfoSpecificKey));
    return testSectionArray;
  }

  deleteRow(index: number) {
    this.testInformationColumsData.splice(index, 1);
  }

  clipboardCopied(clipboardCopiedData: string[]) {
    if (clipboardCopiedData && clipboardCopiedData.length > 0) {
      clipboardCopiedData.forEach(rowData => {
        if (rowData !== '') {
          let columnValues = rowData.split("\t");
          let tension: IColumn = {
            editable: false, headerName: TestInformation_Procedure, value: columnValues[0], errorMessage: '',
            headerPropertyName: TEST_PROCEDURE
          };
          let specimen: IColumn = {
            editable: false, headerName: TestInformation_Specimen, value: columnValues[1], errorMessage: '',
            headerPropertyName: SPECIMEN_TYPE
          };
          let materialGrade: IColumn = {
            editable: false, headerName: TestInformation_MaterialGrade, value: columnValues[2], errorMessage: '',
            headerPropertyName: MATERIAL_GRADE
          };
          let test: IColumn = {
            editable: false, headerName: TestInformation_Test, value: columnValues[3], errorMessage: '',
            headerPropertyName: TEST_TYPE
          };
          let date: IColumn = {
            editable: false, headerName: TestInformation_Date, value: columnValues[4], errorMessage: '',
            headerPropertyName: TEST_DATE
          };
          let facility: IColumn = {
            editable: false, headerName: TestInformation_Facility, value: columnValues[5], errorMessage: '',
            headerPropertyName: TEST_FACILITY
          };
          let comments: IColumn = {
            editable: false, headerName: TestInformation_Comments, value: columnValues[6], errorMessage: '',
            headerPropertyName: COMMENTS
          };
          let tableRow: ITableRow = { tdColumn: [tension, specimen, materialGrade, test, date, facility, comments], editable: true, colspan: 1 };
          this.testInformationColumsData.push(tableRow);
        }
      });
      this.utilityService.connectionPerformanceSectionRowUpdated.next(true);
    }
  }

  get getTestInforamationData(): ITestInformationDTO[] {
    return this.testInformationColumsData.map(item => {
      let obj = {};
      item.tdColumn.forEach(col => {
        obj[col.headerPropertyName] = col.value;
      });
      return obj;
    }) as ITestInformationDTO[];
  }

  rowUpdated() {
    this.utilityService.testInformationSectionRowUpdated.next(true);
  }

  edit(testSpecimens: ITestSpecimen[]) {
    if (testSpecimens && testSpecimens.length > 0) {
      this.testInformationColumsData = [];
      testSpecimens.forEach(testSpecimen => {
        let tension: IColumn = {
          editable: false, headerName: TestInformation_Procedure, value: testSpecimen.testProcedure, errorMessage: '',
          headerPropertyName: TEST_PROCEDURE
        };
        let specimen: IColumn = {
          editable: false, headerName: TestInformation_Specimen, value: testSpecimen.specimenType, errorMessage: '',
          headerPropertyName: SPECIMEN_TYPE
        };
        let materialGrade: IColumn = {
          editable: false, headerName: TestInformation_MaterialGrade, value: testSpecimen.materialGrade, errorMessage: '',
          headerPropertyName: MATERIAL_GRADE
        };
        let test: IColumn = {
          editable: false, headerName: TestInformation_Test, value: testSpecimen.testType, errorMessage: '',
          headerPropertyName: TEST_TYPE
        };
        let date: IColumn = {
          editable: false, headerName: TestInformation_Date, value: this.utilityService.formatDate(testSpecimen.testDate.toString()), errorMessage: '',
          headerPropertyName: TEST_DATE
        };
        let facility: IColumn = {
          editable: false, headerName: TestInformation_Facility, value: testSpecimen.testFacility, errorMessage: '',
          headerPropertyName: TEST_FACILITY
        };
        let comments: IColumn = {
          editable: false, headerName: TestInformation_Comments, value: testSpecimen.comments, errorMessage: '',
          headerPropertyName: COMMENTS
        };
        let tableRow: ITableRow = { tdColumn: [tension, specimen, materialGrade, test, date, facility, comments], editable: true, colspan: 1 };
        this.testInformationColumsData.push(tableRow);

      });
      // ToDo: Enable the code if you wanted to save test info in Session
      //this.saveTestInfoLocalStorage();
    }
    else {
      this.testInformationColumsData = []
    }
  }

  discard(isEdit: boolean) {
    let connectionTests;
    if (isEdit) {
      connectionTests = this.getTestInfoLocalStorage();
      if (connectionTests != null && connectionTests.length > 0) {
        this.testInformationColumsData = connectionTests;
      };
    }
    if (connectionTests == null) {
      this.testInformationColumsData = [];
    }
  }

}
