import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppAuthModule } from './app.auth.module';
import { AppComponent } from './core/app.component';
import { MainModule } from './modules/main/main.module';
import { RouteModule } from './route.module';
import { TcdbModule } from './modules/tcdb/tcdb.module';
import { LoaderService } from './service/tcdb/loader.service';
import { httpInterceptorProviders } from './interceptors/index';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { SharedModule } from './modules/shared/shared.module';
import { TabStorageService } from './service/tcdb/tabstorage.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppAuthModule.forRoot('api/v1/configuration', 'assets/config/app-config.json'),
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    HttpClientModule,
    MainModule,
    RouteModule,
    RouterModule,
    SharedModule,
    TcdbModule,
    NoopAnimationsModule,

  ],
  providers: [
    LoaderService,
    httpInterceptorProviders,
    TabStorageService,
    MatTooltipModule
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
